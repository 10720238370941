.card-item-details {
  height: 19rem;
  overflow-y: scroll;
}

.cartdetailsGrid {
  gap: 10px;
}

/* .cart-details :hover .quantity {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
} */

.card-item-details::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background: transparent !important;
  visibility: hidden;
}

.addOnCart {
  font-size: 13px;
}

.card-item-details::-webkit-scrollbar-thumb {
  background: #888;
  visibility: hidden;
  transition: visibility 0s 0.2s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.card-item-details:hover::-webkit-scrollbar-thumb {
  background: #888;
  visibility: visible;
  transition: visibility 0s 0.2s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.cartdetailsGrid {
  color: black !important;
}

@media only screen and (max-height: 720px) {
  .card-item-details {
    height: 15rem;
  }

  .card-item-details-pending{
    height: 23rem;
  }
}

@media only screen and (max-height: 716px) and (max-width: 688px) {
  .cart-details {
    top: -32rem !important;
    /* height: 40em;
    overflow-y: scroll; */
    max-height: 40em;
    overflow-y: auto;


  }
}



@media only screen and (max-height: 667px) and (max-width: 375px) {
  .cart-details {
    /* top: -36rem !important; */
    top: -34rem !important;
    /* height: 35em;
    overflow-y: scroll; */
    max-height: 40em;
    overflow-y: auto;
  }

  .card-item-details {
    height: 15rem;
  }
}