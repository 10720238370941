.restaurantSwipper{
    margin: 4em 1em;
}

.Productfoodcartimg{
    height: 20em;
}

.restaurant-info{
    padding-left: 7px;
}