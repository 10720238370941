.recomendationHomeImage {
    width: 1.2rem !important;
}

.homePageCard {
    gap: 10px;
}

.divrecommendationWithImage {
    gap: 10px;
}