.MainHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bolder;
  font-size: 3rem;
  color: #371b17;
}

.removeImg {
  width: 2rem !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 1.5rem !important;
  height: 30px !important;
  width: 28px !important;
  font-weight: bold !important;
}

.SecondaryHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bolder;
  font-size: 2rem;
  color: #371b17;
}

.mainParagraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;
  color: #371b17;
}

.customHr {
  width: 20%;
  color: #371b17;
}

.btn-custom {
  background-color: #371b17;
  color: white;
  border-radius: 5px;
  -webkit-box-shadow: 0 4px 9px -4px #371b17;
  box-shadow: 0 4px 9px -4px #371b17;
}

.btn-custom:disabled {
  opacity: 0.6;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
  display: none;
}

.customContainer {
  padding-right: 5rem;
  padding-left: 5rem;
  margin: 0;
}

.cardShadow {
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5.825px;
  border: 0;
}

.commentLove {
  font-size: 1.5rem;
  color: #3d1e19;
}

.commentLove p {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  color: #3d1e19 !important;
}

.foodcartimg img {
  width: 100% !important;
  height: 17rem;
  /* min-height: 256px; */
  /* min-height: 272px; */
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 6px;
}

.no-image-container{
  width: 100% !important;
  height: 17rem;
  border-radius: 6px;
}

.foodCart img {
  width: 100% !important;
  height: 350px;
  /* min-height: 256px; */
  /* min-height: 272px; */
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 6px;
}

.foodCardWidth {
  max-width: 18rem;
}

.foodcart i {
  margin: 0.2rem;
  padding: 0.2rem;
}

.foodCartText h3 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 1rem;
  color: #3d1e19;
}

.starRating {
  /* font-size: 1rem !important; */
  font-size: 0.8rem !important;
}

.foodCartText h4 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1rem;
  color: #3d1e19;
}

.foodCartText h5 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 0.5rem;
  color: #3d1e19;
}

.foodcart h6 {
  font-weight: 600;
  font-size: 0.9rem;
  color: #371b17;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.foodcart .follow-BUtton {
  font-weight: 500;
  font-size: 0.8rem;
  border-radius: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  color: #ffffff;
  background-color: #371b17;
}

.foodcart .ratingStar {
  color: #3d1e19;
}

.foodCartText p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #606060;
}

.foodcartimg .swiper-pagination-bullet {
  opacity: 100% !important;

  /* opacity: var(--swiper-pagination-bullet-inactive-opacity, .2); */
}

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  background: #ffffff !important;
}

.swiper-pagination-bullet-active {
  background: #ffffff !important;
}

.foodcart .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-family: FontAwesome;
  content: "\f138";
  font-size: 1.5rem;
  color: #ffffff;
  /* display: none; */
}

.foodcart .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  /* content: 'prev'; */
  font-family: FontAwesome;
  content: "\f137";
  font-size: 1.5rem;
  color: #ffffff;
  /* display: none; */
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-family: FontAwesome !important;
  content: "\f138" !important;
  font-size: 1.5rem !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  /* content: 'prev'; */
  font-family: FontAwesome !important;
  content: "\f137" !important;
  font-size: 1.5rem !important;
  /* color: #FFFFFF !important; */
}

.foodcart:hover .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  display: block;
  /* On :hover of div show button */
}

.foodcart:hover .swiper-button-prev:after,
.swiper-rtl .swiper-button-prev:after {
  display: block;
  /* On :hover of div show button */
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 30px !important;
}

.foodcart .swiper-button-prev.swiper-button-disabled {
  display: none !important;
}

.foodcart .swiper-button-next.swiper-button-disabled {
  display: none !important;
}

.foodcart {
  position: relative;
}

.foodcart.test {
  width: 17rem;
}

.cartIcon {
  top: 0.5rem;
  position: absolute;
  color: #ffffff;
  z-index: 2;
  font-size: 1.1rem;
  right: 0.5rem;
  background: white;
  border-radius: 50%;
  border: 2px solid;
}

.productCartIcon {
  top: 0.5rem;
  position: absolute;
  color: #ffffff;
  z-index: 2;
  font-size: 1.1rem;
  right: 1.8rem;
  background: white;
  border-radius: 50%;
  border: 2px solid;
}

.bookMarkIcon {
  top: 0.5rem;
  position: absolute;
  color: #ffffff;
  z-index: 2;
  font-size: 1.1rem;
  left: 0.5rem;
}

.userImg {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.BackBlur {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

@media only screen and (min-width: 689px) and (max-width: 788px) {
  .foodCardWidth {
    max-width: 20rem !important;
  }
}

@media only screen and (min-width: 1215px) and (max-width: 1310px) {
  .commentLove {
    font-size: 1rem;
    color: #3d1e19;
  }

  .commentLove i {
    margin: 0px !important;
  }
}

@media only screen and (min-width: 927px) and (max-width: 1023px) {
  .commentLove {
    font-size: 1rem;
    color: #3d1e19;
  }

  .commentLove i {
    margin: 0px !important;
  }
}

@media only screen and (min-width: 1311px) and (max-width: 1621px) {
  .commentLove {
    font-size: 1rem;
    color: #3d1e19;
  }

  .commentLove i {
    margin: 0px !important;
  }
}

@media only screen and (min-width: 1621px) and (max-width: 1743px) {
  .commentLove {
    font-size: 1rem;
    color: #3d1e19;
  }

  .commentLove i {
    margin: 0px !important;
  }
}

@media only screen and (min-width: 1870px) and (max-width: 2064px) {
  .commentLove {
    font-size: 1rem;
    color: #3d1e19;
  }

  .commentLove i {
    margin: 0px !important;
  }
}

@media only screen and (min-width: 2160px) and (max-width: 2395px) {
  .commentLove {
    font-size: 1rem;
    color: #3d1e19;
  }

  .commentLove i {
    margin: 0px !important;
  }
}

@media only screen and (min-width: 2448px) and (max-width: 2700px) {
  .commentLove {
    font-size: 1rem;
    color: #3d1e19;
  }

  .commentLove i {
    margin: 0px !important;
  }
}

.overFlowhidden {
  overflow: hidden;
}

.buttonDiv .btn {
  background-color: #dbdbdb;
}

.foodModalParent {
  height: 90vh;
}

.foodModalRight {
  overflow-y: scroll;
  max-height: 100%;
  height: 100%;
}

.fixedBtn {
  /* position: fixed; */
  /* bottom: 44px; */
  width: 34%;
}