.discountOfferDiv {
    /* background: rgba(11, 10, 10, 0.35); */
    /* background: linear-gradient(to bottom, transparent 0%, black 114%); */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(30%, transparent), color-stop(96%, black));
    background: -o-linear-gradient(top, transparent 30%, black 96%);
    background: linear-gradient(to bottom, transparent 30%, black 96%);
    position: absolute;
    /* width: 20rem; */
    /* height: 6rem; */
    width: 100%;
    height: 100%;
    bottom: 0;
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
}

.discountOfferDivDetails {
    /* color: white;
  font-weight: 700;
  font-size: 20px;
  height: 100%;
  display: flex;
  align-items: end; */
    color: white;
    font-weight: 700;
    font-size: 20px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* align-items: end; */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;

}

.discount-Price {
    gap: 10px;
}



.plusImage {
    width: 20px !important;
    position: absolute;
    background: white;
    border: 2px solid white;
    height: 20px !important;
    right: 0.5rem;
    top: 0.5rem;
}

.resImg {
    width: 1rem !important;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
}

.color-Discount {
    color: #f1cd7a;
}

.discountImage-offerCard2 {
    /* width: 40rem; */
    width: 100%;
    height: 15vw;
}

.discount-Price del {
    font-size: small;
}

.discountOfferDivDetails p {
    font-weight: 400;
    font-size: 15px;
}

.image-Discount {
    -o-object-fit: cover;
    object-fit: cover;
}

.discountDiv {
    margin: 2rem;
}

.discountImage {
    position: relative;
    gap: 10px;
    /* width: 40rem; */
    width: 100%;
    height: 17vw;
}

.color-Discount-coffee {
    background-color: #3D1E19;
}

.color-Discount-yellow {
    background-color: #f1cd7a;
}

.yellowColor {
    color: black !important;
}

.color-Discount-green {
    background-color: #05944f;
}

.discountImage-offerCard2 {

    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.image-Discount-offerCard3 {
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    max-height: 11vw;
    width: 100%;
    padding: 30px;
}

.BuyNow {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.btn-buyNow {
    background-color: #f1cd7a;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    /* color: white; */
    font-weight: bold;
}

.btn-buyNow:hover {
    background-color: rgba(255, 255, 255, 0.449);
    color: white;
}


.discountImage-offerCard2 .row {
    height: 100%;
}

.offer-Text {
    padding: 15px;
}

.offer-Text h2 {
    color: white;
    font-size: 1.4vw;
}

.image-Discount-offerCard2 {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    -webkit-border-radius: 0px 10px 10px 0px;
    -moz-border-radius: 0px 10px 10px 0px;
    -ms-border-radius: 0px 10px 10px 0px;
    -o-border-radius: 0px 10px 10px 0px;
}

.discountImage img {
    /* width: 20rem;
    height: 16rem; */
    /* width: 60rem; */
    width: 100%;
    /* width: 35rem; */
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
}

.discountImage2 {
    gap: 10px;

}

.discountImage2 img {
    width: 35rem;
}

.OrderNowBtn {
    margin-bottom: 15px;
    padding-left: 15px;
}