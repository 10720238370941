.slider-containerSL {
  padding: 3em 1em 1em 1em;
}

.slider-containerSL .swiper-button-prev,
.slider-containerSL .swiper-button-next {
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"><rect x="0" y="0" width="24" height="24" fill="gray" rx="12" ry="12"/><path d="M10.59 16.41L12 15l6-6-6-6-1.41 1.41L14.17 9z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-size: 55% auto;
  background-position: center;
  overflow: hidden;
}

.slider-containerSL .swiper-button-next {
  background: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"><rect x="0" y="0" width="24" height="24" fill="gray" rx="12" ry="12"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-size: 55% auto;
  background-position: center;
  overflow: hidden;
  color: #ffff;
}

.sliderScheduleTime {
  font-size: 14px;
  /* text-decoration: underline; */
  cursor: pointer;
  /* color: white; */

}

.menu-name {
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0 0.1em;
  color: #251001;
  /* text-align: center; */
}



.slider {
  width: min(500px, 100%);
  box-shadow: 0 10px 10px rgba(0, 0, 0, .12);
  transition: 0.2s ease;
  /* padding: 0.5em 0; */
}

/* .slider div:first-child {
  width: 2em;
  height: 2em;
  overflow: hidden;
} */

.slider img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.menu-image {
  width: 65px;
  height: 65px;
  overflow: hidden;
}

.slider:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2), 0px 6px 6px rgba(0, 0, 0, 0.1);
  margin-right: 20px !important;
}

.menu-image-container {
  width: 2em;
  height: 2em;
  overflow: hidden;
}

.menu-image-smaller {
  width: 1.7em;
  height: 2em;
  overflow: hidden;
}

.bike-img {
  margin-top: 10%;
}

.margin-small {
  margin-top: 5% !important;
}

@media (min-width: 1300px) and (max-width: 1700px) {
  .selectionSingleButton {
    /* min-width: 456px; */
    min-width: 317px;
    color: yellow;
  }
}

@media only screen and (max-width: 395px) {
  .menu-slider-card{
    width: min(19em, 100%) !important;
  }
}


.selectionSingleButton {
  /* min-width: 456px; */
  /* min-width: 317px; */
}

@media only screen and (max-width: 688px) {
  .selectionSingleButton {
    min-width: auto;
  }

  .sliderScheduleTime {
    font-size: 0.7rem !important;
    line-height: 1;
  }

  /* .slider div:last-child {
    width: 1.5rem;
    height: 1.5rem;
    overflow: inherit;
  } */

  .menu-name {
    font-size: .8rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    text-wrap: wrap;
    word-break: break-all;
    width: 5rem;
  }

  /* .slider div:first-child {
    width: 1rem;
    height: 1rem;
    object-fit: contain !important;
    overflow: inherit;
  } */

  .menu-image-smaller {
    width: 1.4em;
    height: 1.8em;
    overflow: hidden;
  }



  .slider-containerSL {
    padding: 2.5rem 2rem 2.5rem 0rem;
  }

  .cardMargin {
    margin: 0;
  }

  .tooltip-Desktop {
    display: none !important;
  }

  .tooltip-Mobile {
    display: block !important;
  }
}

@media only screen and (max-width: 536px) {
  .menu-name{
    width: 10rem;
  }
}

@media only screen and (max-width: 418px) {
  .menu-image-smaller {
    width: 1.3em;
    height: 1.7em;
    overflow: hidden;
  }
}

.large-tooltip {
  position: relative;
}

.large-tooltip::after {
  z-index: 58646200 !important;
  content: attr(title);
  position: absolute;
  bottom: 100%;
  /* Position above the element */
  left: 60%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 1.2em;
  /* Larger font size */
  line-height: 1.5em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  /* transition: opacity 0.3s ease; */
  transition: opacity 0.3s ease, visibility 0s 0.3s;
  width: max-content;
}

.large-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.tooltip-Desktop {
  display: block;

}

.tooltip-Mobile {
  display: none;
}

.large-tooltip {
  z-index: 2147483647;

}

.time-schadule {
  padding: 5px 5px;
  margin-bottom: -10px;
  border-left: 1px solid #6e6565;
  border-top: 1px solid #6e6565;
  border-bottom: 1px solid #6e6565;
  border-right: 1px solid #6e6565;
  border-radius: 5px;
}