.slideup-bar {
  display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    width: 100vw;
    background-color: #ccc;
    z-index: 999;
    cursor: pointer;
  }
  .bottomsheet {
    height: 41%;
    z-index: 998;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    border-radius: 20px 20px 0 0;
}
body.modal-open {
  overflow: hidden;
}
.homeRestaurant{
  width: 5%;
  
}
.homeRestaurantDiv{
  justify-content: space-around;
}
  .bottomsheet.visible {
    transform: translateY(0);
  }
  .BottomBarImg{
    width: 5%;
    margin-left: 23%;
    margin-right: -29%;
  }
  .bottomsheetBtn:active,
  .bottomsheetBtn.selected{
    background-color: #3D1E19;
    color: white;
  }
  .bottomsheetSlideBtn{
    background-color: #3D1E19;
    height: 3rem !important;
    width: 8rem;
    margin: auto;
  }