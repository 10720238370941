.foodItemCart {
  top: 0.5rem;
  position: absolute;
  z-index: 2;
  font-size: 1.1rem;
  right: 0.5rem;
}

.large-tooltips {
  position: relative;
}

.section-type-tag {
  position: absolute;
  z-index: 3;
  top: 2%;
  left: 4%;
  padding: 0.2em 0.5em;
  font-weight: 700;
  border-radius: 0.4rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-type-img-container{
  width: 1em;
  height: 1em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.2em;
}

.section-type-img-container>img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.large-tooltips::after {
  z-index: 58646200 !important;
  content: attr(data-title);
  position: absolute;
  bottom: 100%;
  /* Position above the element */
  left: 60%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 1.2em;
  /* Larger font size */
  line-height: 1.5em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s 0.3s;
  width: max-content;
}

.large-tooltips:hover::after {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.food-quantity {
  background-color: #606060;
  width: 3.5rem;
  height: 1.5rem;
  border-radius: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid white;
  color: white;
  font-size: 0.9rem;
}

.edit-icon {
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  background: #606060;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.2em;
}

.edit-icon>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: invert(1);
}

.no-image-container {
  background-color: #cbcbcb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-image-wrapper {
  width: 10em;
  height: 10em;
  overflow: hidden;
}

.no-image-wrapper>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: grayscale(1);
  opacity: 0.2;
}

.foodCartText {
  padding: 0 0.5em 0.5em;
}

.buy-btn-container {
  padding: 0.1em 0.3em;
  border-radius: 0.5em;
  background-color: #F3E5D2;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.buy-button-icon {
  width: 0.7em;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.2em;
}

.btn-special {
  background-color: white; /* White background for 'Special' sectionType */
}
.buy-button-icon>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.buy-text {
  font-size: 0.8rem;
  font-weight: 600;
}

.buy-btn-disabled{
  color: rgba(0, 0, 0, 0.4);
}

.buy-btn-disabled img{
  filter: contrast(0.1);
}

.radius {
  border-radius: 0.9em;
}

.special {
  background-color: #F3E5D2;
}

.shop {
  background-color: #E3F2FD;
}

.btn-disabled{
  color: gray;
  cursor: not-allowed;
}

.single-star-rating{
  width: 1em;
  height: 1em;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.3em;
}

.single-star-rating>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rating-text{
  font-weight: 600;
  color: black;
}

.food-card-btn{
  font-size: 0.9em;
  border-radius: 0.7em;
  background-color: #371B17;
  color: white;
  border: none;
  padding: 0 0.7em;
}