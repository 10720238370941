.masonry {
  column-count: 4;
  gap: 12px;
}

.masonry .imgsizeMansonry {
  margin-bottom: 5px;
  width: 100%;
  display: block;
}

.photoGridContainer {
  padding-right: 5rem;
  padding-left: 5rem;
}

.restaurantDetailsCardGalleryGrid {
  border: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
}

.galleryGrid {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bolder;
  font-size: 2.5rem;
  color: #371B17;
  margin-top: 1rem;
}

.coverImg {
  width: 10rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;

}

.postDisplayModal .modal-dialog .modal-content {
  background-color: transparent;
}

@media only screen and (max-width: 688px) {
  .photoGridContainer {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .offerHeader h1 {
    font-size: 79px !important;
    color: #371b17;
  }
}