.BottomPopUP {
    border-radius: 8px 8px 0 0;
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    -ms-border-radius: 8px 8px 0 0;
    -o-border-radius: 8px 8px 0 0;
}

.BottomPopUP .bottomsheetSlideBtn {
    background-color: #3D1E19;
    height: 0.5rem !important;
    width: 8rem;
    margin: auto;
    border: round;
    border-radius: 10px;
}

.BottomPopUP button {
    background: none;
}

.BottomPopUP button img {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}