.ol-head {
    font-weight: 800;
    font-size: 1.3rem;

}

.ol-head-roman {
    list-style-type: upper-roman;

}

.ol-head-secondary {
    font-weight: 500;
    font-size: 1.1rem;
}
.detailsBody{
    font-size: 0.8rem;
}
.press {
    display: block;
    /* Remove bullet points; allow greater control of positioning */
    padding: 0;
    /* Override defaults for lists */
    margin: 0;
    /* Override defaults for lists */
    width: 100%;
    /* Get the row full width */
}

.press li {
    display: inline-block;
    /* Get all images to show in a row */
    width: 25%;
    /* Show 4 logos per row */
    text-align: center;
    /* Centre align the images */
}

@media (max-width: 960px) and (min-width: 501px) {
    .press li {
        width: 50%;
    }

    /* Show 2 logos per row on medium devices (tablets, phones in landscape) */
}

@media (max-width: 500px) {
    .press li {
        width: 100%;
    }

    /* On small screens, show one logo per row */
}