.custom-table-dialog .MuiDialog-paper {
  max-height: 80vh;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.available-tables {
  position: relative;
  /* position: absolute; */
  /* display: block; */
  width: 320px;
  /* background-color: white; */
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.bottom-container.hidden .available-tables {
  transform: translateY(-100%);
  opacity: 0;
}

.table-search-box {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.modal-content-wrapper {
  display: flex;
  height: 80vh;
  width: 100%;
}

.table-slider-container {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 0;
  overflow: hidden;
}

.info-container {
  width: 35%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  height: 100%;
}

.bottom-container {
  overflow-y: auto;
}

.bottom-container::-webkit-scrollbar {
  width: 0;
}

.bottom-container:hover::-webkit-scrollbar {
  width: 4px;
}

.bottom-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.bottom-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* .bottom-container {
  overflow-y: auto;
}

.bottom-container::-webkit-scrollbar {
  width: 8px;
}

.bottom-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.bottom-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
} */

.table-card-container {
  /* flex-grow: 1; */
  /* overflow-y: auto; */
  /* width: 340px; */
  width: min(340px, 100%);
  display: block;
  padding-right: 10px;
  /* padding-top: 50px; */
  /* padding-top: 60px; */
  /* padding-bottom: 500px; */
  margin-top: 50px;
  margin-top: 1rem;
}

.image-slider-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-slider-image {
  max-width: 100%;
  max-height: 100%;
}

.table-info {
  padding: 1rem;
  overflow-y: auto;
}

.table-info div {
  cursor: pointer;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  border: none;
  background: transparent;
  z-index: 1;
}

.table-search-box {
  background-color: #fff;
  border: none;
  border-radius: 50px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12);
  font-size: 16px;
  height: 100%;
  margin-bottom: 10px;
  padding: 8px 8px 7px;
  width: 100%;
}

.table-search-box:focus {
  outline: 2px solid black;
}

.table-search-box::placeholder {
  font-size: 14px;
}

.table-card-info p {
  font-size: 12px;
}

.table-card {
  margin: 6px 0px;
  border-radius: 8px;
}

.table-card-title {
  font-weight: bold;
  font-size: 1rem !important;
}

.table-card-text {
  font-size: 14px;
}

.bold-text {
  font-weight: bold;
}

.table-card-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-card-img {
  width: 120px;
  max-height: 90px;
  min-height: 90px;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.selected-table-card {
  border: 2px solid #007bff;
  background-color: #e7f1ff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  padding: 4px;
  transition: background-color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
}

.selected-table-card .table-card-title {
  color: #007bff;
}

@media (max-width: 767px) {
  .custom-table-dialog .MuiDialog-paper {
    max-height: 100vh;
    min-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
  }

  .custom-table-dialog .css-cyxlny-MuiPaper-root-MuiDialog-paper {
    margin: 0;
  }

  .modal-content-wrapper {
    position: relative;
    flex-direction: column;
    /* height: 100vh; */
    height: 100dvh;
  }

  .gray-bar {
    width: 30vw;
    height: 10px;
    background-color: gray;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    /* display: block; */
  }

  .table-slider-container.expanded {
    display: none;
  }

  .table-slider-container {
    width: 100%;
    min-height: 280px;
    max-height: 280px;
  }
  /* 
  .available-tables {
    width: 90%;
  } */

  /* .info-container {
    width: 100vw;
    height: 100vh;
    padding: 1rem;
    overflow-y: auto;
  } */

  .close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 1.5rem;
    border: none;
    background-color: white;
    z-index: 1;
  }

  /* .gray-bar {
    width: 30vw;
    height: 10px;
    background-color: gray;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
  } */

  .info-container {
    width: 100vw;
    transition: transform 0.3s ease, opacity 0.3s ease;
    /* height: calc(100vh - 280px); */
    padding: 1rem;
    /* background-color: red; */
    /* overflow-y: auto; */
    position: relative;
    /* padding-bottom: 50px; */
  }

  .top-section {
    transition: transform 0.3s ease, opacity 0.3s ease;
    /* background-color: blue; */
  }

  .Okbtn-placeOrder {
    height: 40px;
    font-size: 18px;
    font-weight: 700;
  }

  .selected-table-card {
  border: 2px solid #007bff;
  background-color: #f0f8ff;
}

}
