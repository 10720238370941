/* .offer-buttons button {
    border: 1.5px solid #3D1E19;
    text-transform: none;
}

.filterDialogue-Radio .MuiTypography-root {
    margin-right: auto !important;
} */

label:before {
    content: none !important;
}

/* .priceRangeDiv .MuiSlider-root {
    color: #3D1E19 !important;
} */

/* .filterDialogue-Radio .MuiIconButton-colorSecondary {
    color: #3D1E19 !important;

} */

.filterDialogueRadio .form-check-input:focus {
    border-color: #3D1E19;
    outline: none;
    box-shadow: none;
}

.filterDialogueRadio .form-check-input:checked {
    background-color: #3D1E19;
    border: none;
    color: #3D1E19;
}

.filterDialogueBtn {
    /* width: fit-content; */
    white-space: nowrap;
    color: #3D1E19;
}

.filterDialogueBtn-check:checked+.filterDialogueBtn,
.filterDialogueBtn.active,
.filterDialogueBtn.show,
.filterDialogueBtn:first-child:active,
:not(.filterDialogueBtn-check)+.filterDialogueBtn:active {
    background-color: #3D1E19;
    color: white;
    border-color: #3D1E19;
}

.filterDialogueBtn:focus {
    min-width: none;
}

input[type=range]::-webkit-slider-thumb {
    background-color: #3D1E19;
}

input[type=range]::-moz-range-thumb {
    background-color: #3D1E19;
}

.filterDialogueBtn:hover {
    background-color: #3D1E19;
    color: white;
    border-color: #3D1E19;
}

.filterDialogueBtn:focus-visible {
    color: white;
    background-color: #3D1E19;
    border-color: #3D1E19;
    outline: 0;
    box-shadow: none;
}

/* .filterDialogueRadio .form-check-input:checked::before {
    background-color: red !important;
  } */
@media only screen and (min-width: 500px) and (max-width: 575px) {
    .filterDialogueBtn {
        font-size: 93%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 499px) {
    .filterDialogueBtn {
        font-size: 78%;
    }
}

@media only screen and (min-width: 400px) and (max-width: 449px) {
    .filterDialogueBtn {
        font-size: 65%;
    }
}

@media only screen and (min-width: 300px) and (max-width: 399px) {
    .filterDialogueBtn {
        font-size: 63%;
    }
}