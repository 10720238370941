/* .modal.show .modal-dialog {
    max-width: 65em;
    width: 100%;
} */

/* @media only screen and (max-width: 574px) {
    .modal.show .modal-dialog{
        padding-right: 1em;
    }
} */
.price-modal{
    max-height: 91dvh;
    overflow-y: auto;
}

.price-modal::-webkit-scrollbar{
    background: #0000 !important;
    height: 6px !important;
    visibility: hidden;
    width: 6px !important;
}

.price-modal::-webkit-scrollbar-thumb,
.price-modal:hover::-webkit-scrollbar-thumb{
    background: #888;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    -webkit-transition: visibility 0s 0.2s;
    transition: visibility 0s 0.2s;
}

.price-modal::-webkit-scrollbar-thumb{
    visibility: hidden;
}

.price-modal:hover::-webkit-scrollbar-thumb{
    visibility: visible;
}

.table-container::-webkit-scrollbar{
    background: #0000 !important;
    height: 6px !important;
    visibility: hidden !important;
    width: 6px !important;
}

.table-container::-webkit-scrollbar-thumb,
.table-container:hover::-webkit-scrollbar-thumb{
    background: #888 !important;
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
    -webkit-transition: visibility 0s 0.2s !important;
    transition: visibility 0s 0.2s !important;
}

.table-container::-webkit-scrollbar-thumb{
    visibility: hidden;
}

.table-container:hover::-webkit-scrollbar-thumb{
    visibility: visible;
}