.RestaurantDetailCard {
  margin: 2rem 0rem;
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  background-color: #f9f9f9;
  border: none;
}

.viewSelector img {
  width: 1rem;
}

.padding-zero {
  padding: 0px !important;
  overflow: hidden;
}

.infoLogo img {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  object-fit: contain;
}

.infoLogo {
  height: 15rem;
  width: 15rem;
  overflow:hidden;
  border-radius: 50%;
}


.allPostATag {
  text-decoration: none;
  color: #371b17;
  /* font-weight: bold; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.restaurantDetailsViewContainer {
  padding-left: 3rem;
  padding-right: 3rem;
}

.imageCarouselResturent {
  -o-object-fit: cover;
  object-fit: cover;
  height: 26rem;
  min-width: 10rem;
}

/* 
.resturantPageCarousel#carouselExampleIndicators {
    margin: 0px !important;

} */

.recommendationImageDetails .price {
  color: #ecd193;
  font-weight: 550;
  font-size: 15px;
}

#carouselResturant {
  margin-bottom: 9rem;
  width: 50rem;
}

.starImage {
  height: 30px;
  width: 32px;
}

.menuSelection .cardManu {
  width: 366.67px;
}

.recommendationDiv {
  margin: 2rem;
}

.recommendationDiv .d-flex {
  gap: 15px;
}

.recommendationImage {
  position: relative;
  width: 100%;
}

.pillBtnOnes {
  width: 11rem;
}

.pillBtnThrees {
  width: 12rem;
}

.recommendationImage .img-fluid {
  width: 100%;
  height: 16rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}

.fkTGdC {
  background-image: url('https: //go5050uk.blob.core.windows.net/go5050images/06560432-ba5c-4384-96e3-1a07eafdd979') !important;
}

.dispayCardWithTwoImage {
  /* background-color: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25); */
  padding: 10px;
  border-radius: 10px;
}

.createPostModal {
  font-size: 1.25rem;
  font-weight: 800;
  margin-left: 40px;
}

.resturentPageWhatsOnYourMindBtn {
  background: #f1f3f6;
  border: none;
  text-align: start;
  color: #371b17d9;
  padding-left: 13px;
  font-weight: 600;
}

.whatsOnYourMindCard {
  margin-bottom: 10px;
  padding: 10px;
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  background-color: #f9f9f9;
  border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  gap: 10px;
}

.shareYourthoughts {
  width: 20rem;
  height: 35px;
  border-radius: 20px;
}

.shareYourthoughtsDiv {
  gap: 5px;
  margin-bottom: 10px;
  margin-top: 25px;
}

.rounded-img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.recommendationImageDetailsDiv {
  background: rgba(11, 10, 10, 0.35);
  position: absolute;
  /* width: 20rem; */
  width: 100%;
  height: 5rem;
  bottom: 0;
  border-radius: 0px 0px 10px 10px;
}

/* span {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
} */

.modal-body {
  position: relative;
}

.image-drag-container {
  position: absolute;
  bottom: 2%;
  right: 3%;
  color: white;
  width: 100%;
}

.recommendationImageDetails {
  color: #f1cd7a;
  font-weight: 700;
  font-size: 20px;
}

.recommendationImageDetails h6 {
  font-weight: 700;
  font-size: 18px;
}

.brandName {
  color: #f1cd7a;
  font-weight: 700;
}

.ratingNumber {
  color: #3d1f1b;
  font-weight: 700;
}

.RestaurantDetailCard {
  background-color: #f9f9f9;
  border: none;
  box-shadow: 0 10px 10px #0000001f;
  margin: 2rem 0;
  margin-left: -1rem !important;
  margin-right: -1rem !important;
  padding: 0.5rem;
}

.ResturentCategory h6 {
  font-size: 16px;
  color: #3d1f1b;
  font-weight: 600;
  margin-bottom: 2px;
}

.location p {
  margin-bottom: 2px !important;
}

.location {
  gap: 10px;
}

.opening {
  gap: 10px;
  margin-bottom: 3px;
}

.followBtn-ResturantPage {
  background-color: hsl(22, 94%, 14%);
  color: white;
  /* width: 65px; */
  border-radius: 5px;
  height: 22px;
  margin-left: 10px;
  border: transparent;
  font-size: 13px;
  /* margin-top: 5px; */
}

.ResturentDetails {
  /* font-weight: 700; */
  font-size: 17px;
  color: #3d1f1b;
}

.ResturentDetails p {
  /* font-weight: 700; */
  margin-bottom: 0;
}

.RestaurantDetailCard .card-body {
  padding-bottom: 0.5rem;
}

.row-gallery-postview {
  max-height: 932px;
  overflow-y: scroll;
  /* hide scrollbar by default */
}

/* .row-gallery-postview:hover {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent;
}

.row-gallery-postview:hover::-webkit-scrollbar-thumb {
  background-color: #bcc0c4;
  border-radius: 20px;
}

.row-gallery-postview:hover::-webkit-scrollbar {
  width: 8px;
} */

.row-gallery-postview::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background: transparent !important;
  visibility: hidden;
}

.row-gallery-postview::-webkit-scrollbar-thumb {
  background: #888;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.2s;
  transition: visibility 0s 0.2s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: fixed;
}

.row-gallery-postview:hover::-webkit-scrollbar-thumb {
  background: #888;
  visibility: visible;
  -webkit-transition: visibility 0s 0.2s;
  transition: visibility 0s 0.2s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.open-close {
  /* padding: 0px 9px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* margin: 4px 2px; */
  cursor: pointer;
  border-radius: 16px;
  font-weight: 700;
}

.open {
  background-color: #059862;
  border: none;
  color: white;
}

.close {
  background-color: #d95051;
  border: none;
  color: white;
}

.row-gallery-postview.scrollbar-track {
  /* background-color: lightgray; */
  background-color: transparent;
}

/* .row-gallery-postview::-webkit-scrollbar-track {
  background: rgb(247, 247, 247);
} */

.row-gallery-postview.scrollbar-thumb {
  /* background-color: gray; */
  background-color: transparent;
  border-radius: 20px;
}

.image-gallery-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #000;
}

.commentImgShowButton {
  background-color: black;
  border: transparent;
}

.commentImgShowButton img {
  width: 2rem;
}

.commentImg .image-gallery .image-gallery-content .image-gallery-slide-wrapper.bottom .image-gallery-swipe .image-gallery-slides .image-gallery-slide.center img.image-gallery-image {
  max-height: calc(95vh - 108px) !important;
}

.commentImg .image-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
}

.CategoryFeatureDiv {
  margin-left: -20px;
}

.FeaturedImg {
  width: 1rem;
}

.FeaturedText {
  margin-bottom: 0;
}

.FeatureDiv {
  gap: 7px;
}

.selectedCategoryFeature {
  -webkit-filter: opacity(0.5);
  filter: opacity(0.5);
}

.FeatureDivCategory {
  padding: 0;
  margin-left: -16px;
  margin-right: -16px;
}

.follower p {
  color: #4f4d4d;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
}

.followBtnDesktop {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.followerMobile {
  display: unset !important;
}

.resLOgoparentDiv {
 height: auto;
}

.parentDivWidth {
  width: 100%;
}

.restaurant-details-logo{
  width: 100px;
  height: 100px;
}

@media only screen and (max-width: 367px){
  .restaurant-details-logo{
    width: 57px;
    height: 57px;
  }
}

@media only screen and (max-width: 853px) {
  .resLOgoparentDiv {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .followBtnDesktop{
    display: none !important;
  }
}

@media only screen and (max-width: 688px) {
  .parentDivWidth {
    width: 100%;
  }

  .followBtnDesktop {
    display: none !important;
  }

  .followerMobile {
    display: block !important;
  }

  .resLOgoparentDiv {
    height: auto;
  }

  .resfollower {
    color: #4f4d4d;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    text-wrap: nowrap;
  }
}

.restaurant-btn-group{
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 611px) {
  .restaurant-btn-group{
    flex-direction: column;
  }

  .restaurant-btn-group .friends-btn{
    width: max-content;
  }

  .restaurant-btn-group .friends-btn > button{
    width: max-content;
  }
}

.opening-hour {
  gap: 10px;
}

.operningHourText {
  color: #371b17;
  cursor: pointer;
}

.album-container {
  max-width: 800px;
  margin: 0 auto;
}

.album-title {
  margin-top: 40px;
  margin-bottom: 20px;
}

.album-thumbnails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
}

.thumbnail-item {
  -ms-flex-preferred-size: 200px;
  flex-basis: 200px;
  max-width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: relative;
}

.thumbnail-item:hover .thumbnail-overlay {
  opacity: 1;
}

.thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.thumbnail-overlay i {
  color: #fff;
  font-size: 24px;
}

.thumbnail-overlay i:hover {
  color: #bbb;
}

.albumHr1 {
  margin: 1px auto;
  background-color: black;
  height: 1px;
  border: 0;
  border-radius: 5px;
}

.albumHr2 {
  margin: 1px auto;
  background-color: black;
  height: 1px;
  border: 0;
  width: 95%;
  border-radius: 5px;
}

.albumCard {
  width: 9rem;
  border: 0;
}

.albumnCardBody {
  height: 52px;
  padding: 4px 13px 4px 2px;
}

.albumnCardImg {
  border-radius: 0;
  border: 0.5px solid gray;
  width: 100%;
  height: 144px;
}

.followHr {
  height: 2px;
  width: 2px;
}

.subscribtionContainer {
  margin-left: 2rem;
  margin-right: 2rem;
}

@media only screen and (max-width: 688px) {
  .subscribtionContainer {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

.viewMenuMessage {
  width: 100%;
  position: fixed;
  top: 0;
  background: rgb(255, 255, 255);
  z-index: 6;
}

/* .slide-down-nav {} */

.slide-up-nav {
  top: 4.3rem;
  width: 100%;
  z-index: 200;
}

@media only screen and (max-width: 688px) {
  .slide-up-nav {
    top: 4.5rem;
  }
}

@media only screen and (max-width: 992px) {
  .slide-up-nav {
    top: 4.3rem;
    padding: 0.5px;
    width: 100%;
    margin: 0.5px;
  }
}

.edit-mode {
  padding: 10px 0 0 0;
}

.cancel-button-group {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1.5em;
  padding: 0.3em 0.7em;
}

.time-icon-container, .cancel-button-i{
  width: 1em;
  height: 1.5em;
  overflow: hidden;
}

.time-icon-container > img, .cancel-button-i > img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: invert(1);
}

.cancel-button-close {
  /* width: 85px;
  height: 19px;
  border-radius: 40px;
  background-color: #059862;
  text-align: left;
  padding-left: 10px;
  font-size: 14px;
  margin-right: -30px;
  color: white; */
  padding: 0 0.6em;
}

.cancel-button-i {
  /* width: 30px;
  height: 24px;
  border-radius: 25px 25px;
  background-color: #059862;
  font-size: 29px;
  text-align: center;
  border: 3px solid #fff;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover; */
}



@media only screen and (max-width: 720px) {
  .cancel-button-group{
    padding: 0.1em 0.5em;
  }

  .cancel-button-close{
    font-size: 0.8em;
  }

  .time-icon-container, .cancel-button-i{
    width: 0.8em;
  }
}

.res-category-name{
  margin: 0px;
  margin-right: 3px;
}

.res-category-name {
  display: inline-block;
  transition: max-width 0.3s ease-in-out, padding 0.3s ease-in-out;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
}