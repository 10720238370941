.user-img {
  height: 36px !important;
  width: 36px !important;
}

.comment-View {
  gap: 10px;
}

.comment p {
  margin-bottom: 2px;
  color: #371b17;
  font-weight: 400;
}

.userName {
  font-size: 10px;
  font-weight: 600;
}

.commentDate {
  font-weight: 500;
}

.likesAndReply h5 {
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
}

.commentImagesDiv {
  width: 100%;
}

@media only screen and (max-width: 688px) {
  .commentImagesDiv {
    width: 100%;
    height: 14rem;
  }
}