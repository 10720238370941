.cart-modal-icon{
    width: 1.8em;
    height: 1.8em;
    overflow: hidden;
    margin: 0 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;

   
}

.cart-modal-icon > img{
    width: 100%;
    height: 1000%;
    object-fit: contain;
    cursor: pointer;
}

.deliveryiconimg{
    border:1px solid;
    border-radius: 50%;
    padding: 3px;
}

.horizontal-line {
    width: 1px; /* Adjust thickness */
    height: 1.6rem; /* Adjust height */
    background-color: #080606; /* Line color */
    margin: 0 0.5rem; /* Space between the line and icons */
  }

  /* .resrating{
    margin-top: 1rem;
  } */
  .resnamerating{
    /* margin-top: 1.5%; */
    font-size: 18px;
  }
  .ratingspan{
    margin-left: 5%;
    margin-top: 0%;
    font-size: 14px;
  }

  @media only screen and (max-width: 550px){
    .itemNameFoodModal{
      font-size: 21px;
    }
  }

  @media only screen and (max-width: 450px){
    .itemNameFoodModal{
      font-size: 15px;
    }
  }