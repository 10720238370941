/* .testContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  grid-gap: 1rem;
} */

.restaurantPageGalleryView .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide.center {
  height: 11.5rem;
}

.restaurantPageGalleryView .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide img.image-gallery-image {
  height: 15rem !important;
  object-fit: cover;
}

.restaurantPageGalleryView img.image-gallery-thumbnail-image {
  height: 54px !important;
  object-fit: cover !important;
}

.testSlider img {
  width: 100%;
  height: 17rem;
  object-fit: cover;
  border-radius: 10px;
}

.foodSelectionContainer {
  padding-right: 5rem;
  padding-left: 5rem;
}

.cardcolor {
  background-color: #f3e5d2;
  /* padding: 3%; */
  border-radius: 15px;
}

.cardcolor .foodCartText {
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
}

.pill {
  position: absolute;
  /* bottom: 10%; */
  top: 0.5rem;
  left: 4px;
  background-color: #f3e5d2;
  color: #3d1e19;
  height: 30px;
  /* width: 80%; */
  font-weight: 900;
  font-size: 13px;
  /* opacity: 0.7; */
  padding: 7px;
  z-index: 998;
}

.cardIcons {
  font-size: 1rem;
  color: #3d1e19;
}

.foodcart .image-gallery-slide.center {
  height: 14.5rem !important;
}

.restaurantPageGalleryView {
  height: 19.3rem;
}

.restaurantPageGalleryView .image-gallery {
  height: 100% !important;
}

.restaurantPageGalleryView .image-gallery-content.bottom {
  height: 100%;
}

.restaurantPageGalleryView .image-gallery-slide-wrapper.bottom {
  height: 80%;
}

.restaurantPageGalleryView .image-gallery-swipe {
  height: 100%;
}

.restaurantPageGalleryView .image-gallery-slides {
  height: 100%;
}

.restaurantPageGalleryView .image-gallery-slide.center {
  height: 100% !important;
}

.profileImg {
  width: 10rem;
  margin-right: 2rem;
}

.dots {
  width: 1.5rem;
  height: 1.5rem;
}

.reviewModalText {
  font-size: 0.8rem;
  text-align: justify;
}

@media only screen and (max-width: 688px) {
  .foodSelectionContainer {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .restaurantPageGalleryView .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide.center {
    height: 30rem !important;
  }

  .restaurantPageGalleryView .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide img.image-gallery-image {
    height: 15rem !important;
    object-fit: cover;
  }

  .restaurantPageGalleryView img.image-gallery-thumbnail-image {
    height: 64px !important;
    object-fit: cover !important;
  }

  .profileImg {
    width: 7.5rem;
    margin-right: 2rem;
  }

  .reviewTitle h3 {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1130px) and (max-width: 1367px) {
  /* .testContainer { */
  /* display: grid;
        grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
        grid-gap: 1rem; */
  /* display: grid;
    grid-auto-flow: row dense;
    grid-auto-rows: var(
      --breakpoint-grid_auto-rows,
      minmax(min-content, max-content)
    );
    grid-gap: var(40px, 0) var(24px, 0); */
  /* grid-template-columns: 10% 10% 10% 10% 10%; */
  /* } */

  .testSlider img {
    height: 14rem;
    object-fit: cover;
  }
}

/* @media only screen and (min-width: 1358px) and (max-width: 1375px) {
  .testContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr)) !important;
    grid-gap: 1rem;
  }
} */

@media only screen and (min-width: 1357px) and (max-width: 1620px) {
  /* .testContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    grid-gap: 1rem;
    grid-template-columns: 24% 24% 24% 24%;
  } */

  .testSlider img {
    height: 18rem;
    object-fit: cover;
  }
}

.swiper-container {
  width: 100%;
  height: 200px;
}

.searchProductWidth .swiper-button-next {
  right: 20px;
  color: #ffff;
}

.swiper-button-prev {
  left: 10px;
  color: #ffff;
}

.foodSelectionContainer .swiper-slide.swiper-slide-active {
  width: 100% !important;
}


.swiper-slide .bg-danger .p-2 .test6 {
  display: contents;
}