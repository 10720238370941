.subscriptionCard {
    /* width: 100%;
    border: solid #371B17;
   
    border-radius: 5px; */
}

.subscriptionCard h3 {
    color: #371B17;
    font-weight: 700;
}

.subscriptionHeader {
    width: 100%;
    height: 5rem;
    background-color: #371B17;
    color: white;
    padding-left: 1rem;
}