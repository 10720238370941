.order-status {
  border-radius: 0.3em;
  padding: 4px 8px;
  background-color: rgba(125, 96, 70, 0.2);
  color: rgb(125, 96, 70);
  width: max-content;
}

.qr-code-btn{
  border: none;
  outline-color: transparent;
  width: 2.5em;
  height: 2.5em;
  overflow: hidden;
  background-color: transparent;
}

.qr-code-btn > img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pending-status {
  background-color: rgba(230, 146, 30, 0.2);
  color: rgba(230, 146, 30);
}

.preparing-status {
  background-color: rgba(19, 176, 76, 0.2);
  color: rgba(19, 176, 76);
}

.ready-status {
  background-color: rgba(19, 82, 176, 0.2);
  color: rgba(19, 82, 176);
}

.out-for-delivery-status {
  background-color: rgba(125, 96, 70, 0.2);
  color: rgba(125, 96, 70);
}

.queue-status {
  background-color: rgba(176, 19, 108, 0.2);
  color: rgba(176, 19, 108);
}
