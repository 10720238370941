.offerHeader {
  font-family: 'poppin';
  font-weight: bold;

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);

  height: 250px;
  font-size: 131px;
  background-color: #f4e5d3;
  border-radius: 10px;
}
.offerHeader h1 {
  font-size: 120px;
  color: #371b17;
}

.offerContainer {
  padding-right: 38px;
  padding-left: 38px;
}

@media only screen and (max-width: 688px) {
  .offerContainer {
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .offerHeader h1 {
    font-size: 79px !important;
    color: #371b17;
  }
}
