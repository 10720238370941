.saucesTag {
    height: 1.4rem;
    /* width: 4rem; */
    border-radius: 50px;
    background-color: #7390e5;

    margin-left: 5px;
}

.saucesTag p {
    color: white;
    font-size: 0.8rem;
    /* margin-left: 1rem; */
    padding: 1px 9px 1px 9px;
    margin: 0px;
}

.option-btn {
    width: 100%;
    border: none;
    outline: none;
    background-color: #F5F5F5;
    padding: 0.5rem 1rem;
    box-shadow: 1px 7px 11px rgba(0, 0, 0, 0.18);
    font-weight: 600;
    color: #380401;
    border-radius: 0.2rem;
}

.option-details {
    border-color: rgba(0, 0, 0, 0.2);
    padding: 0.4rem 0.5rem;
    outline: none;
}

.warning-text {
    font-size: 0.8rem;
    margin-top: 0.3rem;
}

.checkbox-gray {
    accent-color: gray;
}