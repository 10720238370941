@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'swiper/swiper-bundle.min.css';

html, body {
  touch-action: manipulation;
  -webkit-user-select: none; /* Prevents text selection that can trigger zoom on iOS */
  user-select: none;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif;
}