.wholeDivShadow{
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
}
.horizontalCard{
  border-radius: 20px;
}

.horizontalCard img{
  object-fit: cover;
  height: 100%;
}
.horizontalImg img{
  width: 100%;
}

.quantityDiv .form-select {
    border-radius: 1.375rem;
}
.pagePadding{
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: 2rem;
}
.cardratingsDiv img{
  width:2rem;
  height: 2rem;
}
.cardratingsDiv h3{
  font-size: 1.5rem;
  padding-left: 0.5rem;
  margin-bottom: 0px;
}

.horizontalSlider .swiper-button-next{
  right: 1px !important;
  left: auto;
}
.horizontalSlider .swiper-button-prev{
  left: 1px !important;
  right: auto;
}

.horizontalSlider .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal{
   left: 40% !important;
   display: none;
}

.horizontalCard .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper{
   height: 100%;
}
@media only screen and (max-width: 688px) {
  .horizontalCard img{
    object-fit: cover;
    height: 95%;
    width: 100%;
  }
  .pagePadding{
    padding-left: 0rem;
    padding-right: 0rem;
    margin: 0;
  }
  .horizontalCard h5{
    font-size: 0.9rem;
  }
  .horizontalCard p{
    font-size: 10px;
  }
  .quantityDiv{
    
    width: 4.5rem !important;
    height: 2rem;
  }
  .quantityDiv .form-select {
    border-radius: 1.375rem;
    padding: .275rem 2rem .275rem .75rem;
    font-size: 0.8rem;
}
.cardratingsDiv img{
   width:1.5rem;
   height: 1.5rem;
}
.cardratingsDiv h3{
  font-size: 1.2rem;
  padding-left: 0.5rem;
}
}