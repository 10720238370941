.restaurantName {
  text-align: center;
  /* font-weight: 'bold'; */
  color: '#3D1E19';
  font-size: 18px !important;
  padding: 0%;
}

.restaurantHr {
  width: 50%;
  margin: 0 auto;
}

.cartImage {
  height: 400px;
  width: 400px;
}

.cart-item-image {
  width: 9rem;
  height: 6rem;
  overflow: hidden;
}

.cart-item-image>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productDetails h5 {
  font-size: 19px;
  font-weight: 500 !important;
  color: '#3D1E19' !important;

}

.h6,
h6 {
  font-size: 15px;
}

.addOn {
  font-size: 16px;
}

.option {
  font-size: 14px;
}

.suboption {
  font-size: 12px;
}

.plus-icon {
  user-select: none;
}

.addOnCart h5 {
  font-weight: 600;
}

ol {
  padding-left: 1rem;
}

ul {
  list-style-type: disc;
}

.options {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  font-weight: bold;
}

.suboptions {
  font-weight: lighter;
}

.itemIcon {

  width: 3em
}

.fooditem-price{
  font-size: 0.8em !important;
}

.productDetails {
  width: 100%;
  flex-direction: column;
  overflow-wrap: anywhere;
}

@media only screen and (max-width: 458px) {
  .productDetails {
    width: min(10.5em, 100%)
  }
}

@media only screen and (max-width: 426px) {
  .cart-item-image {
    width: 6em;
  }

  .productDetails {
    width: min(9em, 100%);
  }
}

@media only screen and (max-width: 360px) {
  .itemIcon {
    width: 1em
  }

  .cartdetailsGrid {
    color: black !important;
    width: 17em;
  }

  .justify-content-end {
    justify-content: flex-end !important;
    margin-left: 3rem;
  }

  .productDetails {
    width: min(18em, 80%);

    flex-direction: column;
  }

  .pendingPrice {
    justify-content: flex-end !important;
    margin-left: 0rem;
  }


  .pendingcartdetailsGrid {
    gap: 7px;
  }

}