.LandingSevices img {
  max-height: 300px;
  min-height: 250px;
  -o-object-fit: cover;
  object-fit: cover;
}
.custom-card-details {
  font-size: 0.9rem;
  text-align: justify;
}
