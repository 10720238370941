.welcome-container {
  width: min(1000px, 100%);
  margin: 4.8em auto 0;
  display: flex;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.3);
}

.table-img-container {
  width: min(32rem, 100%);
  height: 85dvh;
  overflow: hidden;
}

.table-img-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.restaurant-logo {
  width: 10rem;
  height: 10rem;
  overflow: hidden;
  margin: 0 auto;
}

.restaurant-logo > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.welcome-info {
  flex: 1;
  text-align: center;
  position: relative;
  padding: 2rem;
}

.welcome-title {
  font-size: clamp(1.5rem, 2.8vw, 2.8rem);
}

.welcome-title,
.welcome-table {
  font-weight: 600;
}

.welcome-cta {
  width: 100%;
  position: absolute;
  bottom: 10%;
  display: flex;
  justify-content: center;
}

.welcome-btn {
  background-color: black;
  color: white;
  padding: 0.8em 4em;
  border: none;
  outline: none;
  border-radius: 0.7em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.btn-icon {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  margin-left: 1em;
}

.btn-icon > img {
  filter: invert(1);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.table-number {
  background-color: #7480ff;
  color: white;
  padding: 0.1em 0.5em;
  border-radius: 0.4em;
}

@media only screen and (max-width: 980px) {
  .welcome-container {
    flex-direction: column;
  }

  .table-img-container {
    width: 100%;
    height: 22rem;
  }

  .restaurant-logo {
    margin: 1em auto 0;
  }

  .welcome-cta {
    margin: 2em 0;
  }

  .welcome-info,
  .welcome-cta {
    position: static;
  }
}
