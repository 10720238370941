.swiperPreviewImage {
  height: 40rem;
  width: 68rem !important;
  max-height: 60rem !important;
}

/* button.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  filter: opacity(0.5);
}

button.image-gallery-thumbnail.active.image-gallery-thumbnail-inner {
  filter: opacity(0) !important;
  -webkit-filter: opacity(0) !important;
} */
button.image-gallery-thumbnail {
  filter: brightness(0.5);
  -webkit-filter: brightness(0.5);
}

button.image-gallery-thumbnail.active {
  filter: brightness(1);
  -webkit-filter: brightness(1);
}

img.image-gallery-thumbnail-image {
  border-radius: 7px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 3px solid #3d1f1b !important;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.imageGalleryView
  .image-gallery
  .image-gallery-content
  .image-gallery-slide-wrapper.bottom
  .image-gallery-swipe
  .image-gallery-slides
  .image-gallery-slide.center
  img.image-gallery-image {
}

.btn-Buy {
  background-color: #f3e5d2;
  border: none;
  color: #371b17;
  padding: 5px 9px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  font-weight: 700;
  font-size: 0.6rem;
}

.image-gallery-thumbnail:hover {
  outline: none;
  border: 3px solid #3d1f1b !important;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

button.btn-Preview {
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5);
  border-radius: 10px;
  padding: 0 !important;
  border: 0 !important;
  background: transparent !important;
  margin: 10px;
  width: 20rem;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

button.btn-Preview img {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  width: 9rem;
}

.card-Test2 {
  /* width: 18rem; */
  width: 18rem;
  /* height: 10rem !important; */
  margin: 10px;
  border: 0;
}

.card-test2-font-size {
  font-size: 1rem;
}

.card-test2-font-size-p {
  font-size: 0.8rem;
}

.card-img-test2 {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

button.btn-Preview.active {
  -webkit-filter: none;
  filter: none;
}

.carousel-indicators {
  margin-bottom: -8rem !important;
}

div#carouselExampleIndicators {
  margin-right: 36px;
}

.recommendedSideBar {
  max-height: 48rem;
  overflow-x: scroll;
}

.recommendedSideBar::-webkit-scrollbar-thumb {
  background: #888;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.2s;
  transition: visibility 0s 0.2s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  /* position: fixed; */
}

.recommendedSideBar:hover::-webkit-scrollbar-thumb {
  background: #888;
  visibility: visible;
  -webkit-transition: visibility 0s 0.2s;
  transition: visibility 0s 0.2s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.imageGalleryView
  .image-gallery
  .image-gallery-content
  .image-gallery-slide-wrapper
  .image-gallery-swipe
  .image-gallery-slides
  .image-gallery-slide.center {
  height: 37.5rem;
  background: black;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .imageGalleryView {
    margin: 0 10rem;
  }
}

.imageGalleryView img {
  background-color: black;
}

img.image-gallery-thumbnail-image {
  height: 70px;
  -o-object-fit: cover;
  object-fit: cover;
}

.carousel-item img {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 100%;
}

.carousel-size {
  height: 600px;
  background-color: black;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
}

.mySwiper24 {
  margin-right: 1rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.mySwiper23 {
  /* margin: 0px !important; */
  width: 35rem;
  margin-top: 10px;
}

.test2 swiper-container {
  width: 33%;
  /* margin-left: 19rem; */
  margin-right: 10rem !important;
  margin-right: 0;
  width: 66rem;
  max-height: 40rem;
}

.imagePreviewDisplay {
  width: 8rem !important;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 6rem;
  -o-object-fit: cover;
  object-fit: cover;
}

.mobileDisplay {
  display: none;
}

.desktopDisplay {
  display: block;
}

@media only screen and (max-width: 992px) {
  .mobileDisplay {
    display: block !important;
  }

  .desktopDisplay {
    display: none;
  }

  div#carouselExampleIndicators {
    /* margin-right: 36px; */
    /* margin: 10px !important; */
  }

  .resturantPageCarousel#carouselExampleIndicators {
    margin: 0px !important;
    margin-bottom: 9rem !important;
  }

  .customContainer-order-page {
    margin-top: 3rem !important;
  }

  .carousel-indicators {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: start !important;
    margin-left: 0 !important;
    overflow-x: scroll;
    width: 100%;
  }

  .imagePreviewDisplay {
    width: 7rem !important;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 6rem;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .card-Test2 {
    width: auto !important;
  }
}
