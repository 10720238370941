.shareYourThoughtspopUpImg {
  gap: 5px;
  margin-bottom: 10px;
}

.shareYourThoughtspopUpImg h6 {
  font-weight: 700;
}

.whatsonyourMindtextArea:focus {
  border: none !important;
  box-shadow: none !important;
}

.loading-image-container{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: white; */
  z-index: 10;
}

.addToYourPost {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 7px;
  align-items: center;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.addToYourPost img {
  cursor: pointer;
  font-weight: 400;
}

.post-modal .modal-header {
  padding-top: 0;
}

.addToYourPost h6 {
  justify-content: center;
  align-items: center;
}

.selectRestaurantModal {
  font-size: 1.25rem;
  font-weight: 800;
  margin-right: 40px;
}

.cropText {
  font-size: 1.25rem;
  font-weight: 800;
  padding-left: 14%;
}

.button-container {
  position: relative;
  width: fit-content;
  cursor: pointer;
  float: right;
}

.post-button-container {
  position: relative;
  width: fit-content;
  float: left;
  margin-left: 4px;
}

.pill-button {
  /* display: inline-block; */
  font-size: 11px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.564);
  color: white;
  padding: 8px 10px;
  border-radius: 20px;
  text-decoration: none;
}

/* Add this CSS in your stylesheet */
/* @keyframes button-click {
  0% {
    transform: scale(1);
    background-color: gray;
  }
  50% {
    transform: scale(1.1);
    background-color: green;
  }
  100% {
    transform: scale(1);
    background-color: gray;
  }
} */
@keyframes button-click {
  0% {
    transform: scale(1);
    background-color: gray;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  50% {
    transform: scale(1.1);
    background-color: rgb(24, 65, 24);
  }
  100% {
    transform: scale(1);
    background-color: gray;
  }
}


.pill-button.animate {
  /* animation: button-click 0.3s ease-in-out; */
  animation: button-click 0.3s infinite;
}

.cross-icon {
  margin-left: 2px;
  cursor: pointer;
  color: #888;
  background-color: white;
  border-radius: 50%;
  padding: 1px 4px;
}

.cross-icon:hover {
  color: black;
}

.edit-image {
  border-radius: 0 0 10px 10px;
}

.image-crop-container {
  height: 498px;
  width: 498px;
  background-color: lightgray;
}

.crop-container .reactEasyCrop_CropArea {
  box-shadow: none !important;
  border: 1px solid rgb(169, 166, 166);
}

.crop-container .reactEasyCrop_CropArea::before {
  border: 1px solid rgb(169, 166, 166);
}

.crop-container .reactEasyCrop_CropArea::after {
  border: 1px solid rgb(169, 166, 166);
}

.crop-container .reactEasyCrop_Container {
  top: 59px !important;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 40%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
  z-index: 5;
}

/* .slider {
  padding: 22px 0px;
} */

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.crop-button {
  background: rgba(26, 26, 26, 0.8);
  border-radius: 50%;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  opacity: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  transition-duration: 0.2s;
  transition-property: opacity;
  z-index: 3;
  color: white;
  padding: 8px;
  margin-bottom: 4%;
  margin-left: 4%;
  width: 8%;
}

.crop-button:hover {
  opacity: 0.5;
}

.crop-modal {
  height: 130px;
  width: 130px;
  background-color: rgba(26, 26, 26, 0.8);
  position: absolute;
  bottom: 5%;
  left: 17%;
  transform: translate(-50%, -50%);
  z-index: 5;
  padding: 10px;
  color: white;
  border-radius: 8px;
}

.crop-modal span {
  cursor: pointer;
  color: gray;
  padding-right: 10px;
}

.crop-modal .selected span {
  color: whitesmoke;
  font-weight: 600;
}

.crop-modal hr {
  margin: 6px 0;
}

.crop-container .original-image-container {
  top: 59px !important;
}

.original-image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  user-select: none;
  touch-action: none;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cropped-image {
  user-select: none;
  pointer-events: none;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 10px; /* Adjust height as needed */
  width: 100%;
  background: linear-gradient(to right, rgb(32, 31, 31), #bebebe 0%);
  border: none;
  outline: none;
  border-radius: 5px;
  overflow: hidden;
}
