.FoodHistory .swiper-button-prev {
    /* color: #3D1E19; */
    color: #D9D9D9;
}

.FoodHistory .swiper-button-next {
    /* color: #3D1E19; */
    color: #D9D9D9;

}

.FoodHistory img {
    min-height: 10rem;
    max-height: 10rem;
    -o-object-fit: cover;
    object-fit: cover;
}


.FoodHistory {
    padding: 4rem 4rem 4rem 0rem;
}

.FoodHistoryCard {
    height: 16rem;
}

.FoodHistory h5 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 16px;
    color: #3D1E19;
}

.FoodHistory p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;

    color: #606060;
}

.FoodHistory h4 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 14px;
    color: #3D1E19;
}