.searchBarIcon {
  width: 1.1rem;
}

.backIcon {
  width: 1.3rem;
  height: 1.3rem;
}

.searchTextAreaDiv .form-control:focus {
  border-color: transparent !important;
  box-shadow: 0 0 0 0.1rem rgba(55, 27, 23, 0.65) !important;
}

.circularSearchIcon {
  /* background-color: #371B17; */
  padding: 7px;
  border-radius: 50%;
  width: 2.3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
}

.circularInputText {
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.circularSearch .visibility {
  display: none;
  opacity: 0;
  transition: opacity 0s, opacity 1s linear;
  -webkit-transition: opacity 0s, opacity 1s linear;
  -moz-transition: opacity 0s, opacity 1s linear;
  -ms-transition: opacity 0s, opacity 1s linear;
  -o-transition: opacity 0s, opacity 1s linear;
}

.visibleDiv {
  gap: 10px;
  width: 100%;
}

.circularSearch .visibility {
  opacity: 1;
}

.circularSearch {
  /* background-color: gray; */
  padding: 5px;
  padding-right: 0px !important;
  border-radius: 10%;
  -webkit-border-radius: 10%;
  -moz-border-radius: 10%;
  -ms-border-radius: 10%;
  -o-border-radius: 10%;
  gap: 10px;
}
.searchTextAreaDiv .css-13cymwt-control {
  border-radius: 20px;
  width: 10rem;
}
.css-b62m3t-container {
  z-index: 9999;
}
.css-qbdosj-Input:focus-visible {
  outline: none;
}
.css-qbdosj-Input input {
}
.searchTextAreaDiv .css-t3ipsp-control {
  border-radius: 20px;
  width: 10rem;
  box-shadow: rgba(55, 27, 23, 0.65) !important;
  border-color: rgba(55, 27, 23, 0.65);
}
.searchTextAreaDiv .css-t3ipsp-control:hover {
  border-color: rgba(55, 27, 23, 0.65) !important;
}
