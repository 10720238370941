.LeftPic {
  height: 200px;
  width: 300px;
  border-radius: 30px;
}

.icon {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.arrow {
  /* padding: 4rem 4rem 4rem 0rem; */
  padding: 50px 150px 50px 150px;
  /* border: 1px solid #4caf50; */
}
.borderLinezero {
  border: 0px !important;
}
.starImage {
  height: 30px;
  width: 32px;
}

/* .starRating {
    position: relative;
    left: 100px;
    
  } */

.OrderCartDetails {
  width: 100%;
  /* height: 35rem; */
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin-bottom: 2rem;
  border: none;
}

.Orderfoodcard .card-body {
  padding: 0px;
}

.cartButton {
  padding: 10px;
  /* margin-left: 10rem; */
}

.inputField {
  /* width: 25rem; */
  height: 5rem;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
}

.OrderPostDisplay {
  margin-bottom: 1rem;
}

.AddTocart {
  font-size: 13px;
  font-weight: bold;
  height: 37px;

  border-style: none;
  width: 150px;

  background-color: hsl(0, 0%, 96%);

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  color: rgb(54, 1, 1);
}

/* .dsrButton {
  font-size: 13px;
  font-weight: bold;
  height: 37px;

  border-style: none;
  width: 240px;

  background-color: hsl(0, 0%, 96%);

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  color: rgb(54, 1, 1);
} */

.portionButton {
  font-size: 13px;
  font-weight: bold;
  height: 37px;

  border-style: none;
  width: 100px;

  background-color: hsl(0, 0%, 96%);

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  color: rgb(54, 1, 1);
}

.textColor {
  color: #380401;
  /* font-family: 'poppin'; */
  font-weight: 700;
}

/* Default radio button styles */

/* input[type='radio'] {
  display: none; 
} */

/* Custom radio button styles */

.checkBoxOrderPage.form-check-input:checked {
  content: "";

  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgb(10, 219, 149);
  margin-right: 10px;
  padding-left: 0px;
}

/* Change color of checked radio button */
.checkBoxOrderPage.form-check-input:checked {
  background-color: rgb(10, 219, 149);
  /* set your desired color */
  border-color: rgb(10, 219, 149);
  /* set your desired color */
}

.dollarCenter {
  text-align: end;
  /* padding-right: 40px; */
}

.dsrButton:hover {
  color: white;
  background-color: rgb(54, 1, 1);
}

/* .quantityBtn {
  width: 65px;

  background-color: hsl(0, 0%, 96%);
  margin: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  color: rgb(54, 1, 1);
} */
.quantityBtn {
  width: 65px;
  height: 33px;
  background-color: hsl(0, 0%, 96%);
  margin: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  color: rgb(54, 1, 1);
  border: transparent;
}

.paragraph {
  padding: 20px;
  text-align: justify;
}

/* .productRating {
    justify-content: flex-end;
  } */

.productHeading {
  color: hsl(22, 94%, 14%);
}

.titleColor {
  color: rgb(54, 1, 1);
}

.toggleSection {
  padding-left: 13px;
}

.toggleTittle {
  padding-left: 0px;
  padding-top: 10px;
}
.toggleTittle p {
  margin-bottom: 0px;
}

.toggleSwitch {
  margin-right: 50px;
}

.orderCart {
  /* margin-left: 16px; */
}

.ordercartTitle {
  font-weight: bold;
  color: rgb(54, 1, 1);
}

.wholeTable {
  width: 100%;
  /* margin-left: 12px; */
}

.thTitle {
  font-size: 15px;
}

.asteric {
  font-size: 50px;
}

.tdTitle {
  /* width: 220px; */
}

.toggleTittle .form-check-input {
  background-color: #98adf7;
}

.toggleTittle .form-check-input:checked {
  background-color: #98adf7;
  /* border-color: #0d6efd; */
}

.toggleTittle .toggleSwitch {
  margin-right: 20px;
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("./../../../Assets/Icons/circle.png") !important;
}

.form-switch .form-check-input {
  background-color: white;
  background-image: url("./../../../Assets/Icons/circle (1).png") !important;
  border: 2px solid #98adf7;
}

.form-check-input:checked {
  background-color: #98adf7;
  /* border-color: #98adf7; */
  border: transparent;
}

.seeMoretd {
  width: 100%;
  text-align: center;
}

.seemore {
  color: rgb(54, 1, 1);
  text-decoration: none;
  cursor: pointer;

  /* padding-left: 120px; */
  font-weight: bold;
  font-size: 15px;
}
/* .checkBoxOrderPage input{
  display: none;
} */
.plusCircle {
  color: black;

  font-size: 1.1rem;

  background: #e2e2e2;
  border-radius: 50%;
  border: transparent;
  width: 2rem;
  height: 2rem;
  text-align: center;
  cursor: pointer;
}
.plusCircle p {
  margin: auto;
  padding-top: 3px;
}

.table-buttons{
  display: flex;
  flex-wrap: wrap;
}

.add-btn-disabled{
  background-color: #635553 !important;
  color: white !important;
  opacity: 1 !important;
}

.icon-container{
  width: 1.3em;
  height: 1.3em;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1em;
}

.icon-container > img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.icon-no-background{
  filter: invert(1);
  background-color: transparent;
}

@media only screen and (max-width: 688px) {
  .seemore {
    color: rgb(54, 1, 1);
    text-decoration: none;
    cursor: pointer;

    /* padding-left: 88px; */
    font-weight: bold;
    font-size: 15px;
  }

  .OrderCartDetails {
    /* width: 100%; */
    /* height: 35rem; */
    box-shadow: none;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
    /* border-radius: 10px; */
    border: none;
    padding: 0.5rem !important;

    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .paragraph {
    padding: 0px;
    text-align: justify;
  }
}

.checkbox-gray{
  accent-color: gray;
}