.LeftPic {
  height: 200px;
  width: 300px;
  border-radius: 30px;
}

.icon {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.arrow {
  /* padding: 4rem 4rem 4rem 0rem; */
  padding: 50px 150px 50px 150px;
  /* border: 1px solid #4caf50; */
}

.starImage {
  height: 30px;
  width: 32px;
}

/* .starRating {
  position: relative;
  left: 100px;
  
} */

.OrderfoodcardDetails {
  /* width: 30rem; */
  /* height: 35rem; */
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  padding: 20px;
  margin-bottom: 2rem;
  border: none;
}

.OrderfoodcardDetailsParagraph {
  width: 100%;
  /* height: 35rem; */
  /* box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12); */
  /* padding: 5px; */
  margin-bottom: 2rem;
  border: none;
}

.cartButton {
  padding: 10px;
  /* margin-left: 10rem; */
}

.inputField {
  /* width: 25rem; */
  height: 5rem;
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
}

.OrderPostDisplay {
  margin-bottom: 1rem;
}

.AddTocart {
  display: inline-block;
  font-size: 13px;
  color: azure;
  position: relative;
  cursor: pointer;
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  background-color: rgb(10, 219, 149);
}

.textColor {
  color: rgb(10, 219, 149);
}

/* Default radio button styles */

/* input[type='radio'] {
  display: none; 
} */

/* Custom radio button styles */
label:before {
  content: "";

  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid rgb(10, 219, 149);
  margin-right: 10px;
  padding-left: 0px;
}

/* Change color of checked radio button */
input[type="radio"]:checked + label:before {
  background-color: rgb(10, 219, 149);
  /* set your desired color */
  border-color: rgb(10, 219, 149);
  /* set your desired color */
}

.dollarCenter {
  text-align: center;
}

.dsrButton {
  width: 250px;
  /* cursor: pointer; */
  background-color: hsl(22, 94%, 14%);
  margin: 10px;
  color: white;
}

.onselecteddsrButton {
  background-color: #725c58 !important;
  color: white !important;
}

.paragraph {
  padding: 20px;
  text-align: justify;
}

/* .productRating {
  justify-content: flex-end;
} */

.productHeading {
  color: hsl(22, 94%, 14%);
}

.restaurantlogo {
  width: 70px;
  height: 66px;
}

.mobileDisplay .swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.5rem !important;
  height: 30px;
  width: 28px;
  font-weight: bold;
}

.recomended-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bolder;
  font-size: 2rem;
  color: #371b17;
}

.topChange {
  top: -301px;
}

.bottomChange {
  bottom: 0px;
}

.scrollBox {
  overflow-y: auto;
  max-height: 100vh;
}

.scrollBox {
  overflow-y: scroll;
  max-height: 100vh;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: hidden;
}

.recommendedSideBar::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background: transparent !important;
  visibility: hidden;
}

.scrollBox::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background: transparent !important;
  visibility: hidden;
}

.scrollBox::-webkit-scrollbar-thumb {
  background: #888;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.2s;
  transition: visibility 0s 0.2s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: fixed;
}

.scrollBox:hover::-webkit-scrollbar-thumb {
  background: #888;
  visibility: visible;
  -webkit-transition: visibility 0s 0.2s;
  transition: visibility 0s 0.2s;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.rightSidePanel {
  position: sticky;
  top: 0;

  height: 100%;
  transition: 0.9s all ease-in-out;
  -webkit-transition: 0.9s all ease-in-out;
  -moz-transition: 0.9s all ease-in-out;
  -ms-transition: 0.9s all ease-in-out;
  -o-transition: 0.9s all ease-in-out;
}

.restaurantName {
  color: hsl(22, 94%, 14%);
  font-weight: 600;
  font-size: 25px;
  /* padding-right: 20px; */
}

.OrderPageratingStar {
  font-size: 25px;
  font-weight: 600;
  color: #471b04;
}

.restaurantRating {
  height: 25px;
  width: 25px;
}

.followButton {
  background-color: hsl(22, 94%, 14%);
  margin: 10px;
  color: white;
  width: 65px;
  border-radius: 5px;
  height: 22px;

  margin-left: 10px;
  border: transparent;
  font-size: 13px;
  margin-top: 5px;
}

.sidebar {
  position: relative;
  height: 100%;
}

.resturantDetailsOnMobile {
  margin-top: 10px;
  font-size: 1.3rem;
}

.card-design {
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  /* border-radius: 10px; */
}

.sidebar-inner {
  width: 100%;
  position: absolute;
  /* transition: .9s all ease-in-out; */
}

.ResturentCategory h6 {
  font-size: 16px;
  color: #3d1f1b;
  font-weight: 600;
}

.suggestionDivMobile {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.customOrderCartPadding {
  padding-left: 5px;
  padding-right: 3px;
}

@media only screen and (max-width: 688px) {
  /* .orderCartRow {
    margin: 0px 0px 0px 0px;
  } */

  .paragraph {
    padding: 0px;
    text-align: justify;
  }

  .OrderfoodcardDetails {
    /* width: 30rem; */
    /* height: 35rem; */
    /* box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
    padding: 20px; */
    margin-bottom: 2rem;
    border: none;
  }

  .postSlider {
    width: 100% !important;
  }
}

.postSlider {
  /* width: 40rem; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.swiper-button-next:after, .swiper-button-prev:after{
  color: white;
}
