.header {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bolder;
  font-size: 2rem;
  color: #371b17;
}

.cardtest2 {
  width: 188px;
  height: 20rem !important;
  /* margin: 10px; */
  border: 0;
}

.suggestionswiperDiv .swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.5rem !important;
  height: 30px;
  width: 28px;
  font-weight: bold;
}

/* .swiperDiv .swiper-slide {
  width: 50vw !important;
} */
.suggestionSwiper.swiper-slide.swiper-slide-active {
  width: 50% !important;
}

@media only screen and (max-width: 688px) {

  /* .suggestionswiperDiv {
    width: 330px;
  } */
  .cardtest2 {
    width: 150px;
    height: 20rem !important;
    /* margin: auto; */
    border: 0;
  }

  .header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bolder;
    font-size: 2rem;
    color: #371b17;
    margin-left: 5px;
  }
}