.order-details-container {
  display: flex;
  flex-direction: column;
}

.header-weight-bold {
  font-weight: 600;
}

.parent-header-shadow {
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.parent-body-shadow {
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
}

.table-selection {
  padding: 6px 12px;
  font-size: 16px;
  color: #ffffff;
  background-color: black;
  /* border: 2px solid #4caf50; */
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.table-selection:hover {
  background-color: rgba(26, 25, 25, 0.849);
  color: #ffffff;
}

/* Tips window */
.tips-window{
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* background-color: rgba(36, 68, 68, 0.199); */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px;
  padding: 20px 10px;
}

.tips-button-group{
  /* display: flex;
  flex-direction: row !important;
  gap: 10px;
  margin: 0px 5px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(80px, 100%), auto));
  gap: 1em 0.5em;
}

.price-button {
  /* width: 70px;
  padding: 3px 5px;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  color: black;
  font-weight: bold;
  border: none;
  box-shadow:rgba(141, 132, 132, 0.856) 2px 4px 5px;
  border-radius: 10px; */

  border: none;
  background-color: rgb(255, 255, 255);
  color: black;
  padding: 0.1em 1em;
  border-radius: 0.9em;
  font-size: 0.9rem;
  font-weight: bold;
  box-shadow: 2px 4px 5px rgba(141, 132, 132, 0.856);
}

.controlled-font-size{
  font-size: clamp(0.8em, 1vw, 0.9em);
}

.price-button-active {
  background-color: rgb(70, 2, 2);
  color: #fff;
}

.price-button:hover{
  background-color: rgb(70, 2, 2);
  color: white;
}

.specify-group{
  /* display: flex;
  flex-direction: row !important;
  gap: 10px;
  margin: 0px 5px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0.5em 0 0;
  flex-wrap: wrap;
  row-gap: 0.8em;
}

.specify-input{
  /* box-sizing: border-box;
  border: none;
  box-shadow:rgba(141, 132, 132, 0.856) 2px 4px 5px;
  border-radius: 20px;
  padding: 0px 20px;
  text-decoration: none;
  outline: none;
  display: none; */

  border: none;
  border-radius: 0.9em;
  box-shadow: 2px 4px 5px rgba(141, 132, 132, 0.856);
  outline-color: transparent;
  padding: 0.2em 0.8em;
  width: 100%;
  display: none;
}

.specify-input-active{
  display: inline-block;
}

@media only screen and (max-width: 560px) {
  .specify-group{
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
  }
}