.checkout-container {
  margin-top: 5rem;
}

.checkOutRestaurantImage {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  width: 64px;
  height: 64px;
}

.deliveryDetails .cartdetailsGrid .itemDetailsAndImage img {
  object-fit: cover;
  width: 6rem;
  border-radius: 10px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.restaurantName {
  font-size: 2rem;
}

.restaurantNameDisplay {
  gap: 10px;
  padding: 50px;
  padding-left: 0;
  padding-right: 0;
}

.btn-placeOrder {
  height: 64px;
  font-size: 18px;
  font-weight: 700;
}

.sticky-position {
  position: sticky;
  top: 1px;
}

.orderDetails h5 {
  font-size: 16px;
  margin-bottom: 15px;
}

.warning-allergies {
  font-size: 12px;
  color: gray;
}

.checkOutCard {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.DeliveryHeader {
  font-size: 24px;
  font-weight: 700;
  padding: 10px;
}

.roundedCorner {
  border: 1px solid rgba(128, 128, 128, 0.352);
  border-radius: 10px;
}

.selected-roundedCorner {
  border: 1px solid rgb(128, 128, 128);
  border-radius: 10px;
}

.deliveryDetails {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.hr-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.addressCheckOut {
  flex-direction: column;
  width: 100%;
}

.locaionDetails {
  gap: 10px;
  width: 100%;
}

.city {
  font-size: 15px;
}

.order-container{
  overflow: hidden;
  height: 28em
}

.order-container:hover{
  overflow-y: auto;
}

.order-container::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.order-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.order-container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 0.4em;
}

/* Handle on hover */
.order-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.extra-charges{
  margin-left: 15em;
}

.dot{
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
  margin: 0 2px;
}

.restaurant-logo-container,
.restaurant-img-container {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  margin-right: 0.7em;
}

.restaurant-logo-container img,
.restaurant-img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.restaurant-img-container {
  width: 9rem;
  height: 9rem;
}

.sub-options {
  font-size: 0.8rem;
  color: rgb(0, 0, 0, 0.6);
}

.accordion-body {
  margin: 0 auto;
  /* width: min(60em, 100%); */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
}

.accordion-button::after {
  display: none;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

.pending-food-card {
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  border-radius: 0.5em;
  padding: 1em;
  margin: 0.7em 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 990px) {
  .accordion-body{
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 834px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 560px) {
  .accordion-body>div>div {
    flex-direction: column;
  }

  .history-quantity {
    margin: 3em 0;
  }
}

.accordion-button:not(.collapsed) {
  background-color: rgba(0, 0, 0, 0.07);
  color: black;
}

@media only screen and (max-width: 688px) {
  .restaurantNameDisplay {
    gap: 10px;
    padding: 10px;
  }

  .restaurantName {
    font-size: 1.7rem;
  }
}

.scrollable-container {
  overflow: hidden;
  width: min(700px, 100%);
}

.scrollable-addresses:hover .scrollable-container {
  overflow-y: auto;
}

.nav-link {
  color: gray !important;
  /* Change inactive tab text color to gray */
}

.nav-link.active {
  color: #000 !important;
  /* Change inactive tab text color to gray */
  font-weight: 500;
}

.text-cursor-pointer{
  cursor: pointer;
}