#galleryModal .modal-content {
    /* border-radius: 21px; */
    background: transparent;
}

#galleryModal .modal-content .modal-body img {
    /* border-radius: 21px; */
    max-width: 100%;
    max-height: 95vh;
    /* margin: auto; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* max-width: 45rem;
    width: 45rem;
    height: fit-content; */
}

#postModal .modal-content .modal-body img {
    /* border-radius: 21px; */
    max-width: 100%;
    max-height: 95vh;
    /* margin: auto; */
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* max-width: 45rem;
    width: 45rem;
    height: fit-content; */
}

.postText {
    font-size: 1.5rem;
    font-weight: 500;
}

.modal-body.gallaryViewModelBody {
    padding: 0;
}

#galleryModal .modal-content .modal-body h3 {
    color: #371B17;
    font-weight: 700;
}

.galleryModalDiv {
    /* gap: 10px; */
    /* padding-left: 36px;
    padding-right: 36px; */
}

.galleryModalImgDiv {
    /* max-height: 41rem; */
    /* height: 100vh; */
    max-height: 100vh;
    /* width: 175rem; */
}

.selectedImage {
    /* position: fixed; */
}

.galleryModalBody {
    padding: 0px !important;

}



.galleryModalDivCommentScroll {
    padding-left: 55px;
    padding-right: 55px;
    /* max-height: 41rem; */
    /* max-height: 100%; */
    /* max-width: 30rem; */
    width: 30rem;
    background-color: white;
    padding-top: 20px;
    max-height: 95vh;
    overflow-x: auto;
}

.galleryModalSwipper {
    max-width: 45rem !important;
    padding: 0;
    margin: 0;
    background-color: black;
}

.galleryModalSwipper .swiper-wrapper {
    width: 85rem !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* align-items: center; */
    background-color: black;
    /* justify-content: center; */

    height: 95vh;
    max-height: 95vh;
}

.galleryModalSwipper .swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

button.close.gallaryModelCloseButton {
    z-index: 3;
}

.reply .comment-View {
    margin-left: 45px;
}

.commentScroll {
    /* overflow-y: auto;
    max-height: 100%; */
    max-height: 78%;
    overflow-x: auto;
    margin-bottom: auto;
}

.comment-View .comment p {
    margin-bottom: 2px;
    color: #371B17;
    font-weight: 400;
    /* font-size: 0.8rem; */
    text-align: justify;
}

/* .galleryModalSwiper .swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: 0 !important;
}

.galleryModalSwiper .swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: 0 !important;
} */
.comment-View .userName {
    font-size: 0.8rem;
    font-weight: bold;
}


.commentScroll::-webkit-scrollbar {
    width: 0px !important;
}

.commentScroll::-webkit-scrollbar-track {
    background: red;
}

/* Handle */
.commentScroll::-webkit-scrollbar-thumb {
    background: red;
}

.gallaryModelCloseButton {
    display: none !important;
}

/* .galleryModalImgDiv .swiper-button-prev.swiper-button-prev {
    display: none;
} */

/* Handle on hover */
.commentScroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.CommentComponentDiv {
    display: none;
}

.CommentComponentDivDesktopView {
    display: unset;
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
}

.CommentComponentDivDesktopView .commentComponentDiv {
    margin-top: 10px;
    margin-left: 3.5rem;
}

/* .transform-component-module_wrapper__SPB86 {
    overflow: unset !important;
} */

.previewBtn {
    position: fixed !important;
    left: 0.8rem;
    top: 1rem;
}

.previewBtn img {
    width: 2rem !important;
}

@media only screen and (max-width: 1314px) and (min-width: 990px) {
    .galleryModalSwipper .swiper-wrapper {
        height: 87vh;
    }
}

@media only screen and (max-width: 688px) {
    .galleryModalDiv {
        position: relative;
        /* height: 100vh; */
        /* overflow-y: auto; */
    }

    .transform-component-module_wrapper__SPB86{
        height: 60vh !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .galleryModalSwipper .swiper-button-next {
        top: 50%;
    }

    .galleryModalSwipper .swiper-button-prev {
        top: 50%;
    }

    .commentScroll {
        padding-bottom: 100px;
        /* Adjust this value to give space for the fixed CommentComponentDiv */
    }

    .CommentComponentDivDesktopView {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        padding: 10px;
        z-index: 9999;
    }

    .CommentComponentDiv {
        display: none;
        /* Hide the CommentComponent initially */
    }

}

@media only screen and (max-width: 992px) {
    .galleryModalDivCommentScroll {
        width: 100%;
    }

    .galleryModalSwipper .swiper-wrapper {
        height: 60vh;
    }

    .galleryModalDivCommentScroll {
        height: 40vh;
    }

    .gallaryModelCloseButton {
        display: unset !important;
    }

    #galleryModal .modal-content .modal-body img {
        /* border-radius: 21px 21px 0px 0px; */
    }

    #postModal .modal-content .modal-body img {
        /* border-radius: 21px 21px 0px 0px; */
    }

    .galleryModalDiv {
        position: relative;
        /* height: 100vh; */
        /* set the height to 100% of the viewport height */
        /* overflow: auto; */
    }

    /* .galleryModalDivCommentScroll {
        padding-bottom: 7rem;
    } */

    .CommentComponentDiv {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 999;
        padding: 9px 19px 18px 20px;
        background-color: white;
        display: unset;
    }

    .CommentComponentDivDesktopView {
        display: none;
    }
}