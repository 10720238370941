.text-img {
  margin: 10;
  font-size: 12px;
  text-wrap: nowrap;
}

.filterbar {
  margin: 0 4.5rem 1rem -0.5rem;
}

.filterBarSticky {
  position: sticky;
  top: 0;
  padding: 0.3rem 2rem 0 0;
  margin-bottom: 2px;
}

.row-filterbar.fixed {
  z-index: 6;
}



.filterbar-img-div {
  margin: 8px 0 6px !important;
  padding: 0 10px;
  cursor: pointer;
}

.filterbar-img-div img {
  /* width: 35%; */
  width: 24px;
  height: 26px;
  object-fit: contain;
}

.btn:focus {
  min-width: 0;
}

body.modal-open {
  overflow: hidden;
}

.arrow-button-div {
  width: 2rem;
  height: 2rem;
  border: 0.2rem solid lightgray;
  margin: 14px 12px 4px !important;
}

.arrow-button-div-btn {
  width: 1.2rem;
}

.filterbar-img-div.selected {
  font-weight: bold;
  border-bottom: 2px solid #0060ff;
  background: #72a4f754 !important;
  /* padding: 0px; */
  border-radius: 4px;
  /* padding-left: 4px;
  padding-right: 4px; */
}

.filter-button-div {
  width: 3rem;
}

.model-content-filterDialogue {
  color: #3d1e19;
}

.btn-close-filterdialogueApply {
  background-color: #3d1e19;
  color: white;
}

.btn-close-filterdialogueApply:hover {
  background-color: white;
  color: #3d1e19 !important;
}

.filter-button-div-btn {
  width: 6rem;
  height: 3rem;
  border: 2px solid lightgray;
  border-radius: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 0.7rem;
}

.filter-button-div-btn img {
  width: 1.3rem;
  height: 1.3rem;
}

.btn-close-filterdialogue {
  --bs-btn-font-size: 2rem;
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;
}

.dropdown-toggle::after {
  content: none;
}

.blur {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 300;
  background-color: white;
  padding: 20;
  border-radius: 10;
}

.menuSelectionImgDiv {
  cursor: pointer;
}

.menuSelectionImgDiv.dropdown-item:active,
.menuSelectionImgDiv.dropdown-item.selected {
  background-color: #3d1e19;
  color: white;
}

.dropDownImg {
  width: 14%;
}

.dropDownfilterBarImageIcon {
  width: 2rem;
  padding: 3px;
  border-radius: 50%;
}

.menuSelection-filter {
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
}

.filterbarGap {
  gap: 0;
}

.menuSelectionImgDiv .circularSearch {
  padding: 7px 0px;
}

.menuSelection-filterOnClick {
  width: 90%;
}

.filterbarGapOnClick {
  gap: 35px;
}

.menuSelection-filter::-webkit-scrollbar {
  display: none;
}

.scrollbtns {
  display: none;
  background-color: rgba(255, 255, 255, 0.4);
}

.filterbar-off-searchshow {
  margin: 0 1.5rem 0 0;
  /* padding: 0.3rem 0rem 0 0; */
}

.filterbar-on-searchshow {
  margin: 0 13.5rem 0 0;
}

@media only screen and (max-width: 767px) {
  .arrow-button-div {
    display: none;
  }

  .filter-button-div {
    display: none;
  }

  .menuSelection-filterOnClick {
    width: 100%;
  }

  .menuSelection-filter::-webkit-scrollbar {
    display: none;
  }

  .filterbar-img-div {
    margin: 4px 0 2px !important;
    padding-left: 4px;
    padding-right: 4px;
  }

  .filterbar {
    margin: 0;
  }

  .filterbar-on-searchshow {
    margin: 0 9.5rem 0 0 !important;
  }

  .filterBarSticky {
    position: sticky;
    top: 0;
    padding: 0.3rem 4rem 0 0;
    margin-left: -1.3rem;
  }
}

.filter-bar {
  background-color: transparent;
  transition: all 0.3s ease;
  margin-right: 122px;
  box-sizing: border-box;
}

@media only screen and (max-width: 688px) {
  .text-img {
    font-size: 12px;
  }
  .filter-bar {
    width: 95%;
    background-color: transparent;
    transition: all 0.3s ease;
    /* margin-right: 115px; */
    box-sizing: border-box;
  }
}


.hide {
  display: none;
}

.filter-bar.fixed {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
}
.scroll-up {
  z-index: 100;
}

.subSectionSelected{
  background-color: #72a4f754;
  border-radius: 5px;
}