/* styles.css */
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    
  }
  
  .pagination li {
    cursor: pointer;
    margin: 0 5px;
   
   font-size: 0.8rem;
    padding: 10px;
    border: 1px solid #dcdcdc;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  
  .pagination li.active {
    background-color: #a5caf4;
    color: #ffffff;
    border: 1px solid #6b9ce5;
  }
  
  .pagination li.disabled {
    pointer-events: none;
    color: #ccc;
    border: 1px solid #ccc;
  }
  
  .pagination a {
    text-decoration: none;
    color: #333;
    
  }
  