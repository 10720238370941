.testContainer {
  display: grid;
  grid-template-columns: repeat(1, minmax(17rem, 1fr));
  grid-gap: 1rem;
}



.testSlider img {
  width: 100%;
  height: 17rem;
  object-fit: cover;
  border-radius: 10px;
}

.foodSelectionContainer {
  padding-right: 4rem;
  padding-left: 4rem;
}

.cardcolor {
  background-color: #f3e5d2;
  /* padding: 3%; */
  border-radius: 15px;
}

.cardcolor .foodCartText {
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
}

.pill {
  position: absolute;
  /* bottom: 10%; */
  top: 0.5rem;
  left: 4px;
  background-color: #f3e5d2;
  color: #3d1e19;
  height: 30px;
  /* width: 80%; */
  font-weight: 900;
  font-size: 13px;
  /* opacity: 0.7; */
  padding: 7px;
  z-index: 2;
}

.Productfoodcartimg img {
  width: 100% !important;
  height: 17rem;
  /* height: 100%; */
  /* min-height: 256px; */
  /* min-height: 272px; */
  object-fit: cover;
  border-radius: 6px;
}

.FoodSearchProductfoodcartimg img {
  width: 323px !important;
  height: 306px;
  /* height: 100%; */
  /* min-height: 256px; */
  /* min-height: 272px; */
  object-fit: cover;
  border-radius: 6px;
}

.searchProductTitle {
  font-weight: 600;
  font-size: 0.9rem;
  color: #3e1e19;
}

.searchProductRes {
  font-size: 0.8rem;
  font-weight: 500;
  color: #3e1e19;
}

.searchProduct p {
  font-size: 0.5rem;
}

.searchProduct button {
  font-size: 0.6rem;
  background-color: #3e1e19;
  color: white;
  height: 1.2rem;
  margin-top: 6px;
  border-radius: 7px;
  border: transparent;
  margin-right: 1px;
}

.searchProductUser {
  width: 1.5rem;
  height: 1.5rem;
}

.searchProductWidth {
  width: 100%;
}

.Productfoodcartimg .swiper-slide.swiper-slide-active {
  height: 16rem;
}

.MinHeightOfSwiper {
  min-height: 275px;
}

@media only screen and (max-width: 688px) {
  .newFeedback.foodSelectionContainer {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .foodSelectionContainer {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .searchProductWidth {
    width: 100%;
  }
}

@media only screen and (min-width: 1130px) and (max-width: 1367px) {
  .testContainer {
    /* display: grid;
        grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
        grid-gap: 1rem; */
    display: grid;
    grid-auto-flow: row dense;
    grid-auto-rows: var(--breakpoint-grid_auto-rows,
        minmax(min-content, max-content));
    grid-gap: var(40px, 0) var(24px, 0);
    /* grid-template-columns: 10% 10% 10% 10% 10%; */
  }

  .testSlider img {
    height: 14rem;
    object-fit: cover;
  }
}

/* @media only screen and (min-width: 1358px) and (max-width: 1375px) {
  .testContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr)) !important;
    grid-gap: 1rem;
  }
} */

@media only screen and (min-width: 1357px) and (max-width: 1620px) {
  /* .testContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    grid-gap: 1rem;
    grid-template-columns: 24% 24% 24% 24%;
  } */

  .testSlider img {
    height: 18rem;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1008px) and (max-width: 1128px) {
  .Productfoodcartimg img {
    /* width: 333.06px !important;
        height: 316.4px !important; */
    width: 100%;
    height: 100%;
    /* min-height: 260px !important; */
    /* min-height: 310px !important; */
    font-variant: JIS83;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
}

@media only screen and (min-width: 560px) and (max-width: 1007px) {
  .Productfoodcartimg img {
    /* width: 333.06px !important;
        height: 316.4px !important; */
    width: 100%;

    height: 100%;
    /* min-height: 260px !important; */
    /* min-height: 322.94px; */
    font-variant: JIS83;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
}

@media screen and (min-width: 1879px) {
  .testContainer {
    grid-template-columns: repeat(6, minmax(17rem, 1fr)) !important;
  }
}

@media only screen and (max-width: 1879px) {
  .testContainer {
    grid-template-columns: repeat(5, minmax(19rem, 1fr)) !important;
  }
}

@media only screen and (max-width: 1639px) {
  .testContainer {
    grid-template-columns: repeat(4, minmax(min(17rem, 100%), 1fr)) !important;
  }
}

@media only screen and (min-width: 949px) and (max-width: 1224px) {
  .testContainer {
    grid-template-columns: repeat(3, minmax(min(19rem, 100%), 1fr)) !important;
  }
}

@media only screen and (min-width: 949px) and (max-width: 1024px) {
  .testContainer {
    grid-template-columns: repeat(3, minmax(min(17rem, 100%), 1fr)) !important;
  }
}

@media only screen and (max-width: 949px) {
  .testContainer {
    /* grid-template-columns: repeat(2, minmax(min(17rem, 100%), 1fr)) !important; */
    grid-template-columns: repeat(auto-fit, minmax(min(17rem, 100%), 1fr)) !important;
  }
}

@media only screen and (max-width: 592px) {
  .testContainer {
    grid-template-columns: repeat(1, minmax(min(17rem, 100%), 1fr)) !important;
  }

  .Productfoodcartimg .swiper-slide.swiper-slide-active {
    height: auto;
  }
}

/* @media screen and (min-width: 768px) and (max-width: 820px) {
  .testContainer {
    grid-template-columns: repeat(2, minmax(17rem, 1fr)) !important;
  }

  .foodcart {
    padding-left: 6px;
  }
} */

/* @media screen and (min-width: 768px) and (max-width: 820px) {
  .testContainer {
    grid-template-columns: repeat(2, minmax(17rem, 1fr)) !important;
  }

  .foodcart {
    padding-left: 6px;
  }
} */

/* @media screen and (min-width: 1024px) and (max-width: 1380px) {
  .testContainer {
    grid-template-columns: repeat(2, minmax(17rem, 1fr)) !important;
  }

  .foodcart {
    padding-left: 6px;
  }
} */

/* @media screen and (min-width: 820px) and (max-width: 1180px) {
  .testContainer {
    grid-template-columns: repeat(2, minmax(17rem, 1fr)) !important;
  }

  .foodcart {
    padding-left: 15px;
  }
} */

@media screen and (min-width: 1920px) and (max-height: 1080px) {
  .searchProductWidth {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1400px) {
  /* .testContainer {
    grid-template-columns: repeat(4, minmax(17rem, 1fr)) !important;
  } */

  .searchProductWidth {
    width: 100%;
  }
}

/* RestaurantCard CSS */

.restaurantSwipper .Productfoodcartimg img {
  border-radius: 0px !important;
}

.restaurantSwipper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.restaurantSwipper .swiper-slide img {
  display: block;
  width: auto;
  /* Allow the image to maintain its aspect ratio */
  height: 100%;
  /* Allow the image to maintain its aspect ratio */
  max-width: 100%;
  /* Ensure the image does not exceed the width of its container */
  max-height: 100%;
  /* Ensure the image does not exceed the height of its container */
  object-fit: cover;
  /* Fit the entire image within the container */
}

.restaurantSwipper .swiper-slide {
  width: 80%;
  height: 100% !important;
}

.restaurantDetails {
  padding-top: 10px;
  padding-left: 4px;
  margin-top: 0;
}

.restaurantSwipper .searchProductWidth {
  width: 40rem !important;
}

.restaurantDetails .landingStar {
  color: goldenrod;
  font-size: 1rem;
}

.restaurantDetails h4 {
  margin-bottom: 0;
}

@media screen and (min-width: 1920px) and (max-height: 1080px) {
  .restaurantSwipper .searchProductWidth {
    width: 52rem !important;
  }
}

@media screen and (min-width: 390px) and (max-height: 844px) {
  .restaurantSwipper .searchProductWidth {
    width: 25rem !important;
  }

  .restaurantDetails {
    padding-left: 15px;
  }
}

@media screen and (max-width: 1300px) and (max-height: 677px) {
  .restaurantSwipper .searchProductWidth {
    width: 25rem !important;
  }

  .restaurantDetails {
    padding-left: 15px;
  }
}

@media screen and (max-width: 414px) and (max-height: 896px) {
  .restaurantSwipper .searchProductWidth {
    width: 26rem !important;
  }

  .restaurantDetails {
    padding-left: 15px;
  }
}

@media screen and (max-width: 430px) and (max-height: 932px) {
  .restaurantSwipper .searchProductWidth {
    width: 30rem !important;
  }

  .restaurantDetails {
    padding-left: 35px;
  }
}

@media screen and (min-width: 360px) and (max-height: 740px) {
  .restaurantSwipper .searchProductWidth {
    width: 25rem !important;
  }

  .restaurantDetails {
    padding-left: 35px;
  }
}

@media screen and (min-width: 1280px) and (max-height: 720px) {
  .restaurantSwipper .searchProductWidth {
    width: 40rem !important;
  }

  .restaurantDetails {
    padding-left: 35px;
  }
}

/* .restaurantSwipper .swiper-slide:nth-child(2n) {
  /* display: block;
  width: 100%;
  height: 100% !important;
  object-fit: cover; */
/* border-left: 0 !important; */
/* } */

/* .restaurantSwipper .swiper-slide:nth-child(3n) { */
/* width: 80%; */
/* } */

/* .restaurantSwipper .swiper-slide:nth-child(odd) {
  border-right: 0 !important;
}

.restaurantSwipper .swiper-slide:nth-child(even) {
  border-left: 0 !important;
} */