 .slick-dots li img {
     height: 5rem;
     width: 5rem;

 }

 .slick-dots li {
     height: 5rem;
     width: 5rem;

 }


 .slick-active li {
     width: 0 !important;
 }

 .thumb .discountImage {


     height: 5rem;
     width: 5rem;
 }

 .thumb .discountOfferDivDetails {

     display: none;
 }

 .thumbSlider .discountImage {
     position: relative;
     gap: 10px;
     /* width: 40rem; */
     width: 100%;
     height: 15vw !important;
 }