.image-slider-container {
  position: relative;
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
  overflow: hidden;
}

.image-container {
  width: 100%;
  /* height: 500px;  */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.table-slider-image {
  /* width: 100vw; */
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: all;
  object-fit: contain;
  touch-action: none;
}

.table-slider-image.dragging {
  transition: none;
}

.image-slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;
}

.image-slider-button-prev {
  left: 10px;
}

.image-slider-button-next {
  right: 10px;
}

.zoom-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 2;
  padding: 5px;
  border-radius: 8px;
}

.zoom-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: #333;
}

.zoom-icon.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.react-transform-wrapper {
  width: 100% !important;
}

.transform-component-module_content__FBWxo {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 688px) {
  .transform-component-module_wrapper__SPB86 {
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
