.cards-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(25rem, 100%), 1fr));
    row-gap: 2rem;
    column-gap: 0.5rem;
}

.grid-center{
    place-items: center;
}

.food-card{
    /* width: min(25rem, 100%) */
    min-height: 40em;
}

.bold-text{
    font-weight: 600;
}