/* .image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
} */

/* .row {
  display: flex;
  justify-content: center;
} */
.container-black {
  /* width: 80%; */
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  /* border: solid #bbbb; */
  background-color: black;
}

.imageDiv {
  display: flex;
  justify-content: center;
}

.image {
  margin: 0px;
  position: relative;
}

.image img {
  width: 36rem;
  height: auto;
  position: relative;
}

.recommendationImageDetailsDiv2 {
  /* background: rgba(11, 10, 10, 0.35); */
  position: absolute;
  /* width: 20rem; */
  width: 100%;

  height: 3rem;
  bottom: 0;
  border-radius: 0px 0px 10px 10px;
  top: 50%;
  text-align: center;
}

/* .recommendationImageDetails {
  color: #ffff;
  font-weight: 700;
  font-size: 20px;
} */