.subscriptionDiv {
    /* background: linear-gradient(to bottom, transparent 30%, black 96%); */
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    overflow: hidden;

}

.subscriptionDivDetails {
    color: white;
    font-weight: 700;
    font-size: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;

}

.discount-Price {
    gap: 10px;
}



.plusImage {
    width: 20px !important;
    position: absolute;
    background: white;
    border: 2px solid white;
    height: 20px !important;
    right: 0.5rem;
    top: 0.5rem;
}

.resImg {
    width: 1rem !important;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
}

.color-Discount {
    color: #f1cd7a;
}

.subscriptionImage-offerCard2 {
    /* width: 40rem; */
    width: 100%;
    height: 15vw;
}

.discount-Price del {
    font-size: small;
}

.subscriptionDivDetails p {
    font-weight: 400;
    font-size: 15px;
}

.image-Discount {
    object-fit: cover;
}

.discountDiv {
    margin: 2rem;
}

.subscriptionImage {
    position: relative;
    gap: 10px;
    /* width: 40rem; */
    width: 100%;
    height: 17vw;
}

.color-Discount-coffee {
    background-color: #3D1E19;
}

.color-Discount-yellow {
    background-color: #f1cd7a;
}

.yellowColor {
    color: black !important;
}

.color-Discount-green {
    background-color: #05944f;
}

.subscriptionImage-offerCard2 {

    /* border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px; */
}

.image-Discount-offerCard3 {
    object-fit: cover;
    /* border-radius: 50%; */
    /* -webkit-border-radius: 50%; */
    /* -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; */
    max-height: 11vw;
    width: 100%;
    padding: 30px;
}

.BuyNow {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.btn-buyNow {
    background-color: #f1cd7a;
    /* border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px; */
    /* color: white; */
    font-weight: bold;
}

.btn-buyNow:hover {
    background-color: rgba(255, 255, 255, 0.449);
    color: white;
}


.subscriptionImage-offerCard2 .row {
    height: 100%;
}

.offer-Text {
    padding: 15px;
}

.offer-Text h2 {
    color: white;
    font-size: 1.4vw;
}

.image-Discount-offerCard2 {
    width: 100%;
    object-fit: cover;
    height: 100%;
    /* border-radius: 0px 10px 10px 0px;
    -webkit-border-radius: 0px 10px 10px 0px;
    -moz-border-radius: 0px 10px 10px 0px;
    -ms-border-radius: 0px 10px 10px 0px;
    -o-border-radius: 0px 10px 10px 0px; */
}

.subscriptionImage img {

    width: 100%;
    /* width: 35rem; */
    height: 100%;
    object-fit: cover;

}

.subscriptionImage2 {
    gap: 10px;

}

.subscriptionImage2 img {
    width: 35rem;
}

.OrderNowBtn {
    margin-bottom: 15px;
    padding-left: 15px;
}

.subscriptionDetails h5 {
    color: #371B17;
}

.subscriptionDetails p {
    color: #371B17;
}

.subscriptionDetails h6 {
    color: rgb(96, 95, 95)
}

.subscriptionDivDetails h3 {
    color: white;
    margin-bottom: 0px;
}

.OrderNowBtn p {
    margin-bottom: 0px;

}

.sub .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 35%) !important;
}

.sub .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 35%) !important;
}

@media only screen and (max-width: 688px) {


    .subscriptionImage .swiper-button-next {
        top: 30% !important;
    }
}


.Recommendation-carason .slider-wrapper.axis-horizontal {
    height: 14.6rem;
}

.Recommendation-carason .carousel .thumbs-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1rem;
    margin-right: .8rem;
}


.thumbslider ul.thumbs.animated {
    margin: 0;
    padding: 0;
    text-align: center;
}




.Recommendation-carason li.thumb {
    height: 6rem !important;
    width: 6rem !important;
}


.Recommendation-carason .carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid #ffffff;
}

.Recommendation-carason .carousel .thumb {
    border: 3px solid #000000;
}

.Recommendation-carason .carousel .slide img {
    height: 17rem !important;
}

.Recommendation-carason .thumbs-wrapper .axis-vertical img {
    height: 21rem;

}


.Recommendation-carason .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
    height: 21rem;
}

.Recommendation-carason .carousel .thumb img {
    vertical-align: top;
    height: 5.5rem !important;
    width: 5.5rem !important;

}

/* 
.Recommendation-carason .thumbslider .carousel {
    padding-left: 1.5rem;
}

.Recommendation-carason .thumbslider .carousel {
    padding-right: 1.5rem;
}

.Recommendation-carason .carousel .carousel-slider {
    padding: 0 !important;
} */


.Recommendation-carason .thumbslider .subscriptionDiv {
    background: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    overflow: hidden;
    top: -2rem;
}


.subscriptionDiv {
    border-radius: .5rem;
    -webkit-border-radius: .5rem;
    -moz-border-radius: .5rem;
    -ms-border-radius: .5rem;
    -o-border-radius: .5rem;
}



.thumbslider {
    background-color: black;
    border-radius: .5rem;
    -webkit-border-radius: .5rem;
    -moz-border-radius: .5rem;
    -ms-border-radius: .5rem;
    -o-border-radius: .5rem;
}

.newThumb.cards-wrapper {
    display: flex;
    justify-content: center;
}

.newThumb .card img {
    max-width: 100%;
    max-height: 100%;
}

.newThumb .card {
    margin: 0 0.5em;
    box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
    border: none;
    border-radius: 0;
}

.newThumb .carousel-inner {
    padding: 1em;
}

.newThumb .carousel-control-prev,
.newThumb .carousel-control-next {
    background-color: #e1e1e1;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

@media (min-width: 768px) {
    .newThumb.card img {
        height: 1em;
    }
}


.Recommendation-carason .newThumb .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden {
    height: 8rem !important;
}

.thumbCart {
    height: 7rem;
}

.thumbCart img {

    object-fit: cover;
}

.thumbCartmainDiv {
    height: 68%;
}


.thumbCartmainDiv img {

    object-fit: cover;
}

.thumbCart .subscriptionDivDetails {
    height: 73%;
}

.newThumb {
    border-radius: .5rem;
    -webkit-border-radius: .5rem;
    -moz-border-radius: .5rem;
    -ms-border-radius: .5rem;
    -o-border-radius: .5rem;
}