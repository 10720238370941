.loginLeft {
  background-color: #11d48a;
  background-image: url(./../../Assets/Image/loginbg.svg);
  background-size: cover;
}

.loginRight {
  background-color: #d6e4d7;
}

.ortxt h4 {
  font-size: 16px;
}

.goTasteH3 {
  font-size: 22px !important;
}

.loginPageExternalBtn {
  border: 1px solid black;
  width: 100% !important;
}

.googleImg {
  width: 1.2rem;
  position: absolute;
  left: 11px;
}

.go5050Img {
  width: 1.6rem;
  position: absolute;
  left: 11px;
}

.span-text {
  font-size: 12px;
}

i.fa-brands.fa-facebook.mr-2 {
  position: absolute;
  left: 11px;
}

.relativeBtn {
  position: relative;
  justify-content: center;
}

button.btn.login-with.d-flex.align-items-center.w-100.loginPageExternalBtn.relativeBtn:hover {
  background-color: #f7f7f7 !important;
  border: 1px solid black !important;
}

.email-disabled {
  pointer-events: none;
  cursor: not-allowed;
}
