.FoodSelection h2 {
  font-weight: 800;
  font-size: 27px;
  color: #3d1e19;
}

.cartHR {
  display: none;
}

.pillSticky {
  position: fixed;
  top: -100px;
  /*Initialposition*/
  background-color: white;
  /* transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out; */
}

.slide-down {
  top: 0;
  /* Slide down to 0 */
}

.slide-up {
  top: -100px;
  /* Slide up to -100px */
}

.foodcart-img {
  height: 36px !important;
  width: 36px !important;
}

.starRating i {
  color: #371b17;
}

.userName {
  color: #03a9ff;
}

.pillCardSelect {
  background: #d2cfcf !important;
  border: none !important;
  color: white !important;
}

.pillCard {
  /* padding: 7px 10px 7px 10px; */
  gap: 3px;
  border-radius: 50rem !important;
  width: 8rem;
  /* background: white;
    border: 1px solid; */
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 11px !important;
  -ms-border-radius: 11px !important;
  -o-border-radius: 11px !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.scrollbtn {
  background-color: white;
  display: none;
  /* background-color: rgba(255, 255, 255, .4); */
}

.pillSwiper .swiper-slide {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 8rem !important;
}

.pillSwiper .swiper-wrapper {
  margin-right: -5px !important;
  width: 9rem !important;
}

.pillSticky {
  width: 100%;
  display: block;
}

.pillItem {
  gap: 7px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 9px;
}

.pillDisplay {
  margin-top: 10px;
}

.pillCard h2 {
  font-size: 16px;
  margin: 0;
}

.pillCard img {
  width: 20%;
}

.pillSwiper {
  width: 100%;
  height: 46px;
  position: sticky;
  top: 5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  margin-top: 7px;
  margin-left: 0.4rem;
}

/* .pillBtnOne {
    width: 6rem !important;
}

.pillBtnTwo {
    width: 8rem !important;
    height: 34.5px;
}

.pillBtnThree {
    width: 7rem !important;
} */

.comment-View p {
  font-size: 10px;
}

.foodcart p {
  margin-bottom: 0;
}

.rating-Font {
  font-size: 13px;
}

.slideDownMore {
  top: 75px !important;
}

.FoodSelection .swiper-button-next {
  color: #fff;
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.FoodSelection .swiper-button-next:hover {
  color: lightgray;
  background-color: #ffffff;
}

.FoodSelection .swiper-button-prev {
  color: #fff;
  background-color: #ffffff;
}

.FoodSelection .swiper-button-prev:hover {
  color: lightgray;
  background-color: #ffffff;
}
