.mobileDisplay {
  display: none;
}

.pillDisplay {
  display: none !important;
}

@media only screen and (min-width: 689px) and (max-width: 788px) {


  .galleryImage img {
    width: 48%;
  }

  .galleryImg img {
    /* width: 255px; */
    width: 32%;
    height: 11rem !important;
    /* border-radius: 10px; */
    margin: 2px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .recommendationImage .img-fluid {
    /* width: 20rem; */
    width: 100%;
    height: 9rem !important;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
  }

  .foodCardWidth {
    max-width: 25rem;
  }
}


.mobileDisplay-featured {
  display: none !important;
}

@media only screen and (max-width: 992px) {
  .searchInput {
    width: 100% !important;
  }
}

.desktopDisplay-Opening {
  display: unset !important;
}

.moreBtn {
  display: none !important;
  text-decoration: none;
  color: #2e1612;
}

@media only screen and (max-width: 400px) {
  .albumCard {
    width: 11rem !important;
    border: 0;
  }
}

@media only screen and (max-width: 360px) {
  .albumCard {
    width: 10rem !important;
    border: 0;
  }
}

.mobileViewForFollow {
  display: none !important;
}

.btnCheckOutMarginTop {
  bottom: 60px !important;
}

.btnCheckOutMarginTopZero {
  bottom: 0px !important;
}

.desktopCart {
  display: unset !important;
}

.mobileCart {
  display: none !important;
}

.typewriterDiv {
  font-size: 4rem !important;
  color: white;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  margin-left: 8rem;
  font-weight: 600 !important;
}

/* mobile devices */
@media only screen and (max-width: 688px) {
  .banner {
    height: 40vh !important;
  }

  .ol-head-secondary {
    padding-left: 0;
  }

  .ol-head-third {
    padding-left: 1rem;
  }

  .btn-buyNow {
    width: 100% !important;
  }

  .HeroSection {
    height: 100% !important;
    padding-top: 5rem;
  }

  .topImg {
    width: 100% !important;
  }

  .custom-ui {
    width: 100vw !important;
  }

  .btnCheckOut {
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
    left: 0;
    background-color: white;
  }

  .checkOutCard {
    margin-top: 10px;
  }

  .warning-allergies {
    margin-bottom: 60px;
  }

  .btnCheckOut .btn-placeOrder {
    height: 50px !important;
  }

  .deliveryDetails .cartdetailsGrid .itemDetailsAndImage img {
    height: 4rem !important;
    width: 4rem !important;
    -o-object-fit: cover !important;
    object-fit: cover !important;
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -ms-border-radius: 10px !important;
    -o-border-radius: 10px !important;
  }

  .desktopCart {
    display: none !important;
  }

  .mobileCart {
    display: unset !important;
  }

  .postSwiper img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
  }

  .postSwiper {
    width: 102vw !important;
    padding: 0;
    margin: 0;
    background-color: black;
  }

  .mobileViewForFollow {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .PostCard .card-body {
    width: 101vw;
  }

  /* .allPostATag {
        margin-top: 0px !important;
    } */
  .mobileMarginTop {
    margin-top: 0.5rem;
    justify-content: center;
  }

  .moreBtn {
    display: unset !important;
  }

  .opening {
    gap: 10px;
    margin-bottom: 3px;
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .search-field {
    width: 100% !important;
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    height: 56px !important;
  }

  .searchInput {
    width: 100vw !important;
  }

  .mobileDisplay-featured {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .discountImage-offerCard2 {
    /* width: 40rem; */
    width: 100% !important;
    height: 40vw !important;
  }

  .subscriptionImage-offerCard2 {
    /* width: 40rem; */
    width: 100% !important;
    height: 40vw !important;
  }

  .offer-Text h2 {
    font-size: 4.4vw !important;
  }

  .image-Discount-offerCard2 {
    height: 40vw !important;
  }

  .discountImage {
    position: relative;
    gap: 10px;
    width: 100% !important;
    height: 100% !important;
  }

  .subscriptionImage {
    position: relative;
    gap: 10px;
    width: 100% !important;
    height: 100% !important;
  }

  .orderCartRow {
    margin-left: 5px;
    margin-right: 5px;
  }

  .cart {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    /* -ms-flex-direction: column !important; */
    /* flex-direction: column !important; */
    width: 95% !important;
  }

  .cart-details {
    width: 100% !important;
    top: -40rem !important;
    right: 0rem !important;
    bottom: 4.5rem !important;
  }

  .typewriterDiv {
    font-size: 1.2rem !important;
    color: white;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    margin-left: 2rem !important;
  }

  .recommendationImage .img-fluid {
    height: 10rem !important;
  }

  .btn-toggle {
    width: 100% !important;
    text-align: start;
  }

  .dropdown-details {
    width: 100% !important;
  }

  .subscribe-btn {
    margin-top: 10px;
  }

  .searchBars {
    gap: 10px;
    margin-left: 0rem !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1rem;
  }

  .orderpageMainDiv {
    overflow-x: hidden;
  }

  .width100 {
    width: 100% !important;
  }

  .imageGalleryView .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide.center {
    height: 30rem !important;
  }

  /* .image-gallery-content .image-gallery-slide .image-gallery-image {
        height: 38rem;
    } */

  .imageGalleryView .image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide img.image-gallery-image {
    height: 30rem;
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .test2 {
    margin-right: -1rem !important;
  }

  /* .image-gallery-slide .image-gallery-image {
        object-fit: cover !important;
    } */

  /* .gallerySwiper.swiper-android .swiper-slide,
    .swiper-wrapper {
        transform: translate3d(0px, 0, 0);
        height: 100vh !important;
    } */
  .galleryModalDivCommentScroll {
    overflow-x: unset !important;
  }

  .commentScroll {
    overflow-x: unset !important;
    overflow-y: unset !important;
  }

  .gallerySwiper .swiper-wrapper .swiper-slide.swiper-slide-active {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: unset !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .galleryModalSwipper .swiper-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .galleryModalSwipper .swiper-wrapper {
    width: 26rem !important;
  }

  .gallerySwiper {
    height: 100% !important;
  }

  .galleryModalSwipper .swiper-wrapper {
    height: auto !important;
  }

  #galleryModal .modal-content {
    /* border-radius: 21px; */
    background: white !important;
  }

  #galleryModal .modal-fullscreen {
    /* width: 100vw; */
    /* max-width: none; */
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    margin: 0;
  }

  #postModal .modal-content {
    /* border-radius: 21px; */
    background: white !important;
  }

  #postModal .modal-fullscreen {
    /* width: 100vw; */
    /* max-width: none; */
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    margin: 0;
  }

  /* .gallerySwiperDiv.swiperGallery.swiper-button-next:after,
    .swiper-button-prev:after {
        display: none;
    } */

  .gallaryModelCloseButton {
    position: fixed !important;
  }

  .row-gallery-postview {
    max-height: 100% !important;
    overflow-y: unset !important;
    margin-left: -16px;
    margin-right: -16px;
    overflow: hidden;
    /* scrollbar-width: thin;
    scrollbar-color: gray lightgray; */
    /* max-height: 932px; */
    /* overflow-y: auto; */
  }

  /* .wholeTable {
        width: auto !important;
       
    } */

  .swiper.swiperGallery.swiper-initialized.swiper-horizontal.swiper-ios.gallerySwiper.swiper-backface-hidden.swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    font-family: FontAwesome !important;
    content: '\f138' !important;
    font-size: 1.5rem !important;
    /* display: none; */
    position: fixed;
  }

  /* .swiper.swiperGallery.swiper-initialized.swiper-horizontal.swiper-ios.gallerySwiper.swiper-backface-hidden.swiper-button-next:after,
    .swiper-button-prev:after {
        position: fixed;
    } */

  .ResturentDetails {
    /* font-weight: 700; */
    font-size: 14px !important;
    color: #3d1f1b;
    text-align: justify;
  }

  .carousel-size {
    height: 30rem !important;
  }

  .postImg img {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    max-width: 100%;
    max-height: -webkit-fit-content !important;
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }

  .cardcolor {
    background-color: #2e1612;
    padding: 0% !important;
    border-radius: 15px;
  }

  .shareYourthoughts {
    width: 17rem !important;
    height: 35px;
    border-radius: 20px;
  }

  /* img.rounded-img {
        width: 12% !important;
    } */
  .recommendationImage .img-fluid {
    /* width: 20rem; */
    height: 10rem;
  }

  .OrderfoodcardDetails {
    width: 100% !important;
  }

  .swiperH5 {
    font-size: 0.8rem !important;
    font-weight: 700 !important;
  }

  .dispayCardWithTwoImage {
    width: 100%;
  }

  .discountImage2 img {
    width: 100% !important;
  }

  .subscriptionImage2 img {
    width: 100% !important;
  }

  .card-Test2 {
    /* width: 18rem; */
    width: 18rem;
    /* height: 10rem !important; */
    margin: 10px;
    border: 0;
    -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  }

  .pillSticky .HideSelectionSingleButton {
    margin: 0px !important;
  }

  /* .discountImage img {
        width: 100% !important;
        
        object-fit: cover;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    } */

  .productPageBanner img {
    /* height: 12rem !important; */
    height: 100% !important;
    /* object-fit: cover; */
  }

  .productPageBannerBottom img {
    height: 12rem !important;
    height: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
  }

  /* .productPageBanner img {
        height: 30rem !important;
        object-fit: unset !important;

    } */

  p.p-4.ImageTextInside {
    font-size: 0.8rem !important;
  }

  #carouselResturant {
    margin-bottom: -3rem !important;
    width: 100% !important;
  }

  .recommendationDiv {
    margin: 0rem !important;
  }

  .resturantPageCarousel {
    margin-bottom: 9rem !important;
  }

  .discountDiv {
    margin: 0px !important;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .pillSticky {
    position: sticky;
    top: 0px;
    z-index: 9;
    padding: 0 !important;
  }

  /* .HideSelectionSingleButton .selectionSingleButton {
        display: none !important;
    }

    .HideSelectionSingleButton .selectionSingleButton {
        display: none !important;
    } */

  /* .scrollbtnMobile .selectionSingleButton {
        background-color: black !important;
        display: none !important;
    } */

  .pillDisplay {
    display: unset !important;
  }

  .FoodHistory .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 2rem;
  }

  .FoodHistory .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 2rem;
  }

  .customContainer {
    padding-right: 1rem;
    padding-left: 1rem;
    margin: 0;
  }

  .MainHeader {
    font-size: 30px;
  }

  .foodcart {
    width: 100% !important;
  }

  .foodcartimg img {
    width: 100% !important;
    height: 30rem !important;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }

  .no-image-container{
    width: 100% !important;
    height: 30rem !important;
    border-radius: 0px;
  }

  .cartIcon {
    top: 0.9rem;
    position: absolute;
    color: #ffffff;
    z-index: 2;
    font-size: 1.1rem;
    right: 0.9rem;
  }

  .desktopDisplay {
    display: none !important;
  }

  .desktopDisplay-Opening {
    display: none !important;
  }

  .PostCard {
    margin-right: 0 !important;
  }

  .PostCard {
    margin-top: 1rem !important;
  }

  .RestaurantDetailCard {
    margin: 0px !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .FoodHistoryCard {
    height: 13rem !important;
  }

  .FoodHistory img {
    min-height: 8rem !important;
    max-height: 8rem !important;
  }

  .FoodHistory {
    padding: 2rem 2rem 2rem 0rem !important;
  }

  .FoodHistory p {
    font-size: 12px !important;
  }

  /* .FoodSelection .selectionSingleButton {

        flex-direction: column !important;
    } */

  /* .FoodSelection h2 {
        font-weight: 800;
        font-size: .6rem !important;
        color: #3D1E19;
    }

    .FoodSelection img {
        width: 50%;
    } */

  #galleryModal .modal-content .modal-body .selectedImage {
    margin: 0 !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
  }

  #postModal .modal-content .modal-body .selectedImage {
    margin: 0 !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
  }

  .galleryModalImgDiv {
    width: 100% !important;
    /* height: 100% !important; */
  }

  .selectedImage {
    position: unset !important;
  }

  .galleryModalDivCommentScroll {
    padding-left: 20px !important;
    padding-right: 20px !important;
    /* max-height: 41rem !important; */
    /* max-height: 41rem; */
    /* max-height: 100%; */
    /* max-width: 30rem; */
    width: auto !important;
    background-color: white;
    padding-top: 20px;

    max-height: initial !important;
  }

  .galleryModal {
    margin: 0 !important;
    border: 0 !important;
  }

  #galleryModal .modal-content {
    border: 0 !important;
  }

  #galleryModal .modal-content {
    border-radius: unset !important;
    -webkit-border-radius: unset !important;
    -moz-border-radius: unset !important;
    -ms-border-radius: unset !important;
    -o-border-radius: unset !important;
  }

  #postModal .modal-content {
    border: 0 !important;
  }

  #postModal .modal-content {
    border-radius: unset !important;
    -webkit-border-radius: unset !important;
    -moz-border-radius: unset !important;
    -ms-border-radius: unset !important;
    -o-border-radius: unset !important;
  }

  .foodCartText {
    padding: 0rem 1rem 1rem 1rem;
  }

  .cartHR {
    display: block !important;
  }

  .masonry {
    -webkit-column-count: 3 !important;
    -moz-column-count: 3 !important;
    column-count: 3 !important;
    gap: 8px !important;
  }

  /* .PostCard .card-body {
    padding: 0 !important;
    margin-left: -15px !important;
    margin-right: -30px !important;
  } */
  .PostCard {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  div#carouselExampleIndicators {
    margin-right: 0px !important;
  }
}

/* tablate devices */

@media only screen and (min-width: 1129px) and (max-width: 1639px) {
  .foodcart.test {
    width: 15rem !important;
  }

  /* 
    .rightSidePanel {
        position: -webkit-sticky;
        position: sticky;
        top: calc(100vh-200px) !important;
       
    } */

  .foodcartimg img {
    /* width: 100% !important;
        height: 15rem !important; */
    /* width: 321.8px !important; */
    height: 305.7px !important;
    width: 100% !important;

    -o-object-fit: cover;

    object-fit: cover;
    border-radius: 10px;
  }

  .no-image-container{
    height: 305.7px !important;
    width: 100% !important;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1008px) and (max-width: 1300px) {
  .galleryImg img {
    /* width: 255px; */
    /* border-radius: 10px; */
    margin: 2px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 48% !important;
    height: 14rem !important;
  }

  .recommendationImage .img-fluid {
    width: 100%;
    height: 12rem !important;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1008px) and (max-width: 1128px) {
  .foodcart.test {
    width: 15rem !important;
  }

  .foodcartimg img {
    /* width: 333.06px !important;
        height: 316.4px !important; */
    width: 100%;
    height: 330px;
    /* min-height: 260px !important; */
    /* min-height: 400px !important; */
    font-variant: JIS83;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .no-image-container{
    width: 100%;
    height: 400px;
    border-radius: 10px;
  }
}



@media only screen and (min-width: 560px) and (max-width: 1007px) {
  .foodcart.test {
    width: 15rem !important;
  }

  .foodcartimg img {
    /* width: 333.06px !important;
        height: 316.4px !important; */
    width: 100%;

    height: 323px;
    /* min-height: 260px !important; */
    /* min-height: 322.94px; */
    font-variant: JIS83;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .no-image-container{
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

/* small monitor  */
@media only screen and (min-width: 1010) and (max-width: 1639px) {}

@media only screen and (min-width: 688px) and (max-width: 1439px) {


  .foodSelectionContainer {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .customContainer {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
    margin: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .user-img {
    width: 36px;
  }

  #galleryModal .modal-content .modal-body img {
    width: 100%;
    max-width: 100% !important;
  }

  #postModal .modal-content .modal-body img {
    width: 100%;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 992px) {
  .galleryModalDiv {
    gap: 0px !important;
    /* padding-left: 36px;
        padding-right: 36px; */
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .galleryModalDivCommentScroll {
    padding-left: 36px;
    padding-right: 36px;
  }
}

@media only screen and (min-width: 1129px) and (max-width: 1639px) {
  .foodcartimg img {
    /* height: 311px !important; */
    width: 100% !important;
  }

  .no-image-container{
    width: 100% !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1129px) {
  .foodcartimg img {
    height: 325px !important;
    width: 100% !important;
  }

  .no-image-container{
    height: 380px !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1380px) {
  .foodcartimg img {
    /* height: 380px !important; */
    width: 100% !important;
  }

  .no-image-container{
    width: 100% !important;
  }
}

.foodCardWidth {
  max-width: 100%;
}

/* @media only screen and (max-width: 767px) {
  .box {
    margin: 8px 16px !important;
    border-radius: 8px !important;
  }

  .img-fluid {
    border-radius: 10px;
  }

  .foodcartimg img {
    height: 311px !important;
    width: 100% !important;
    border-radius: 10px;
  }
} */