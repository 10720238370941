.qr-container{
    width: min(650px, 100%);
    margin: 1em auto;
    box-shadow: 0 2px 11px rgba(0, 0, 0, 0.3);
    border-radius: 0.6em;
    position: relative;
    overflow: hidden;
}

.qr-container::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45%;
    background-color: #e3d9cd;
    z-index: -1;
}

.table-number-container{
    display: flex;
    justify-content: flex-end;
}

.table-number{
    background-color: #5662e3;
    color: white;
    padding: 0.2em 0;
    border-radius: 0.9em;
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0 auto;
    width: 11em;
}

.qr-number{
    width: 3em;
    height: 3em;
}

.qr-icon{
    width: 4em;
    height: 4em;
    overflow: hidden;
    margin-bottom: 1em !important;
    margin-top: 1.5em !important;
    background: #0D6EFD;
    padding: 0.5em;
    border-radius: 50%;
}

.logo-container{
    position: absolute;
    top: 24%;
    z-index: 10;
}

.gotaste-logo{
    width: 3em;
    height: 3em;
    overflow: hidden;
}

.gotaste-logo > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.logo-title{
    font-size: 1.5rem;
    font-weight: 700;
}

.qr-icon > img, 
.Qr-container > img, 
.restaurant-logo > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.qr-icon > img{
    filter: invert(1);
}

.Qr-code{
    margin: 2.8em 0;
    position: relative;
    --length: 3em;
    --height: 4px;
    --offset: 15px;
}

.Qr-code::after{
    content: "";
    position: absolute;
    top: -25%;
    left: -14%;
    width: 128%;
    height: 140%;
    background: #ffffff;
    padding: 0em;
    z-index: 1;
}

.Qr-code .line1::before, 
.Qr-code .line1::after, 
.Qr-code .line2::before,
.Qr-code .line2::after,
.Qr-code .line3::before,
.Qr-code .line3::after,
.Qr-code .line4::before,
.Qr-code .line4::after{
    content: "";
    position: absolute;
    background-color: rgb(0, 0, 0);
    z-index: 10;
}

.Qr-code .line1::before, .Qr-code .line1::after{
    top: calc(0px - var(--offset));
    left: calc(0px - var(--offset));
}

.Qr-code .line2::before, .Qr-code .line2::after{
    top: calc(0px - var(--offset));
    right: calc(0px - var(--offset));
}

.Qr-code .line3::before, .Qr-code .line3::after{
    bottom: calc(0px - var(--offset));
    left: calc(0px - var(--offset));
}

.Qr-code .line4::before, .Qr-code .line4::after{
    bottom: calc(0px - var(--offset));
    right: calc(0px - var(--offset));
}

.Qr-code .line1::before,
.Qr-code .line2::before,
.Qr-code .line3::after,
.Qr-code .line4::before{
    width: var(--length);
    height: var(--height);
}

.Qr-code .line1::after,
.Qr-code .line2::after,
.Qr-code .line3::before, 
.Qr-code .line4::after{
    width: var(--height);
    height: var(--length);
}

.Qr-container{
    width: 18em;
    height: 18em;
    overflow: hidden;
    position: relative;
    z-index: 10;
}

.qr-table-number{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7.8em;
    height: 7.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    border-radius: 0.5em;
    overflow-wrap: anywhere;
}

.qr-table-small{
    height: 6.8em;
}

.qr-logo{
    max-width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.qr-logo > img{
    max-width: 100%;
    height: 100%;
    object-fit: contain;
}

.qr-num{
    width: 100%;
    height: max-content;
    font-size: 1.1rem;
    position: absolute;
    z-index: 10;
    padding: 0 0.1em;
    color: black;
    top: 22%;
    left: -7%;
}

.qr-icon{
    margin: 0 auto;
}

.qr-title, .qr-restaurant{
    font-weight: 700;
}

.qr-title{
    font-size: clamp(2rem, 3.4vw, 2.9rem);
}

.qr-subtitle, .qr-created{
    font-weight: 600;
}

.qr-subtitle{
    margin-bottom: 2em;
    word-wrap: break-word;
    max-width: 26em;
    font-size: 1.1rem;
    text-align: center;
}

.restaurant-url{
    display: inline-block;
    /* margin: 0 0 1em; */
    background-color: rgba(255, 255, 255, 11);
    font-size: 0.7rem;
    top: 100%;
    position: absolute;
    width: 100%;
    z-index: 10;
}

.restaurant-logo{
    width: 4em;
    height: 4em;
    overflow: hidden;
}

.restaurant-info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.qr-info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.qr-restaurant{
    font-size: clamp(1.7rem, 2.4vw, 2rem);
}

.qr-footer{
    margin-bottom: 4em;
}

.qr-link{
    color: #5662e3;
    font-weight: 700;
}