/* .divThreeImg .discountImage {
    height: 11rem;
} */

.sideDiv {
  height: 8vw;
}

.sliderCart {
  width: 100%;
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
}

.discountDetails {
  padding: 1rem;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(30%, transparent),
    color-stop(96%, black)
  );
  background: -o-linear-gradient(top, transparent 30%, black 96%);
  background: linear-gradient(to bottom, transparent 30%, black 96%);
  position: absolute;
  color: white;
  bottom: 0;

  overflow: hidden;
}

.threecardSIdecart {
  height: 50%;
}

.threecardSIdecartDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* .threecardSIdecart h4 {
    font-size: 10px;
}

.threecardSIdecart p {
    font-size: 6px;

} */
.logoSideBar {
  width: 5rem;
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-width: 300px;
}

.offsetSignup .login {
  background-color: #f1cd7a;
  color: white;
  font-weight: bold;
}

.offsetSignup .SignUp {
  background-color: #371b17;
  color: white;
  font-weight: bold;
}

.offsetSignup .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
