.postOwnerImg {
  width: 40px;
  height: 42px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.postImg.d-flex.justify-content-center {
  background: black;
  width: 100%;
  padding: 0rem;
}

.postModal {
  padding: 0px !important;
  overflow-y: unset !important;
}

.moreItemModal .btn-close {
  background-color: #e4e6eb;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  color: #1b1b1c;
}

.moreItemModal .modal-body {
  max-height: 24.5rem;
  overflow-y: auto;
  margin-right: 5px;
  padding-right: 7px;
}

.itemButton {
  background-color: #e4e6eb;
  height: 3rem;
  width: 7rem;
  color: #1b1b1c;
}

.moreItemModal .modal-body::-webkit-scrollbar {
  width: 8px;
}

.moreItemModal .modal-body::-webkit-scrollbar-track {
  background-color: white;
}

.moreItemModal .modal-body::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 0 0 #888;
  box-shadow: inset 0 0 0 0 #888;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
}

.moreItemModal .modal-body:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Change thumb color to #888 on hover */
  -webkit-box-shadow: inset 0 0 6px 0 #888;
  box-shadow: inset 0 0 6px 0 #888;
}

.moreItemModal .modal-body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.moreItemsLink {
  font-weight: 700;
  cursor: pointer;
  text-wrap: wrap;
}

.moreItemModal .modal-body {
  max-height: 24.5rem;
  overflow-y: auto;
  margin-right: 5px;
  padding-right: 7px;
}

.postDisplayModal .modal-dialog .modal-content {
  background-color: transparent !important;
}

.postDisplayModal .modal-content .modal-body img {
  /* border-radius: 21px; */
  max-height: 95vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.PostCard .card-body {
  padding: 0 !important;
}

.three-dot {
  display: flex;
  width: 1.5em;
  height: 1.5em;
  justify-content: center;
  align-items: center;
}

.postHeader {
  gap: 10px;
  padding: 1rem;
}

.post {
  padding-left: 1rem;
  padding-right: 1rem;
}

.postComment {
  padding-left: 1rem;
  padding-right: 1rem;
}

.postReply {
  margin: 1rem;
}

.circularDiv {
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  background-color: #f0f2f5;
  overflow: hidden;
  padding: 10px 15px;
  width: 78%;
}

#postModal .modal-content {
  /* border-radius: 21px; */
  background: transparent;
}

.custom-textarea {
  border: none;
  background-color: transparent !important;
  resize: none;
  max-height: 2rem;
  margin-bottom: 5px;
}

.custom-textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-textarea:focus-visible {
  outline: none;
}

.custom-textarea::-webkit-scrollbar {
  outline: none;
}

.editableP {
  outline: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.editableP[contenteditable] {
  display: inline-block;
  color: black;
  margin-bottom: 0;
}

.editableP[contenteditable]:empty::before {
  content: 'Write a comment';
  display: inline-block;
}

.editableP[contenteditable]:empty:focus::before {
  content: '';
}

.postHeader h6 {
  font-weight: 700;
  color: #371b17;
}

.post h4 {
  font-size: 1.5rem;
  font-weight: 500;
}

.postSwiper img {
  width: 100%;
  max-height: 44rem;
  -o-object-fit: contain;
  object-fit: contain;
}

.postSwiper {
  background-color: black;
}

.postSwiper .swiper-wrapper {
  width: 85rem !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: black;
  /* justify-content: center; */
  height: 100%;
}

.postImg img {
  /* max-width: 250px;
    max-height: 250px; */
  /* border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px; */
  max-width: 100%;
  max-height: 41vw;
}

.postReply span {
  border: 0;
  resize: none;
  overflow: hidden;
  min-height: 50px;
  max-height: 100px;
  outline: 0px !important;
}

.PostCard {
  /* margin-right: 2rem; */
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  background-color: #f9f9f9;
  border: none;
}

.hoverModal {
  position: absolute;
  z-index: 999;
  background-color: #ffffffe3;
  width: 10rem;
  padding: 10px 10px 0;
  border-radius: 10px;
  top: 85px;
  left: 100px;
  /* left: 55vw; */
  /* top: 160vh;
    left: 55vw; */
}

@media only screen and (max-width: 992px) {
  .circularDiv {
    width: 100%;
  }
}

.postRectImage {
  /* Add styles for the heart icon */
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.postRectImage.filled {
  /* Add styles for the filled heart icon */
  fill: red;
}

.postRectImage.animate {
  transform: scale(1.2);
}


/* .modal-list-ul li{
  border-bottom: 1px solid #8a8181;
  padding: 5px;
} */

.buy-butoon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 10px 7px;
  border-radius: 50px 50px;
  background-color: #f4e5d2;
  width: fit-content;
  height: 30px;
  cursor: pointer
}