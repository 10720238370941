/* .update-fixed-btn {
    width: 39%;
    position: fixed;
    bottom: 3rem;
} */

@media only screen and (max-width: 767px) {
    .update-fixed-btn{
        bottom: 2% !important;
    }
}

@media only screen and (max-width: 688px) {
    .NewborderLinezero {
        margin-bottom: 4rem;
    }

    /* .update-fixed-btn {
        position: fixed;
        bottom: 1rem;
        width: 90%;
        left: 0;
        right: 0;
        text-align: center;
        margin: auto;
    } */
}