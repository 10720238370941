.order-details-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.base-price-container{
    margin-right: 1.5em;
}

.margin-r{
    margin-right: 1em;
}

.food-name{
    font-weight: 600;
    color: black;
}

.sub-options{
    font-size: 1rem;
    margin-left: 1em;
}

.sub-options-color{
    color: black;
}

.sub-options-margin{
    margin-left: 2em;
}

.text-black{
    color: black;
}

.text-bold{
    font-weight: 600;
}

.total-price{
    display: flex;
    justify-content: space-between;
}

.order-shadow{
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
}

.text-small{
    font-size: 0.9rem;
}

.align-right{
    text-align: right;
}

@media only screen and (max-width: 560px) {
    .order-details-wrapper{
        flex-direction: row !important;
    }

    .total-price{
        flex-direction: row !important;
    }
}