.HeroSection {
    background-image: url("../../Assets/Image/5570863.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* height: 100vh; */
    height: 100vh;
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-size: cover;
}

.rounded-xl {
    border-radius: .5rem;
    -webkit-border-radius: .5rem;
    -moz-border-radius: .5rem;
    -ms-border-radius: .5rem;
    -o-border-radius: .5rem;
}

.card-img-top {
    border-radius: 7px;
    max-height: 300px;
    min-height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
}

.Custom-card-design {
    -webkit-box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, .13), 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, .11);
    box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, .13), 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, .11);
    border-radius: 1.5rem;
    color: #371B17;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    -ms-border-radius: 1.5rem;
    -o-border-radius: 1.5rem;
}

.LandingPage {
    background-color: #faf9fd;
}

.partnerImg {
    width: 20rem;
}

.transparentColor {
    /* background: linear-gradient(315deg, #d5adc8 0%, #ff8489 74%); */
    /* background-color: #de4256; */
    background-image: -o-linear-gradient(135deg, #de4256 0%, #b78e51 74%);
    background-image: linear-gradient(315deg, #de4256 0%, #b78e51 74%);
    text-decoration: none;
    text-transform: none;
    /* color: transparent; */
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.input-icons i {
    color: black;
    position: absolute;
    top: 5px;
}

.container-Herosection {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100vw;
}

.searchBars {
    gap: 10px;
    margin-left: 8.2rem;
}

.input-icons {
    /* width: 100%; */
    margin-bottom: 10px;
    position: relative;
}

.swiper-3d .swiper-slide-shadow-right {
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    border-radius: 1rem !important;
}

.swiper-3d .swiper-slide-shadow-left {
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
    background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
    border-radius: 1rem !important;
}

.icon {
    padding: 10px;
    min-width: 40px;
}

.input-field {
    width: 100%;
    padding: 10px;
    padding-left: 32px;
}

.background-color-2 {
    background: #e9e6f5;
}

.topImg {
    width: 50%;
}

.btn-search-home {
    background-color: #371B17;
    color: white;
    height: 45px;
}

.font-size-for-social-media {
    font-size: 1.5rem;
}

.font-size-for-footer {
    font-size: 1rem;
}

.btn-toggle:hover {
    color: black !important;
}

.btn-toggle:active {
    color: black !important;
}

.btn-toggle.show {
    color: black !important;
}

.btn-search-home:hover {
    background-color: #371B17;
    color: white;
    -webkit-filter: brightness(0.8);
    filter: brightness(0.8);
}


.LandingPage button.accordion-button {
    background-color: #371B17;
    background: -o-linear-gradient(left, #8ca6db, #b993d6);
    background: -webkit-gradient(linear, left top, right top, from(#8ca6db), to(#b993d6));
    background: linear-gradient(90deg, #8ca6db, #b993d6);
    color: white;
}

.LandingPage .accordion-body {
    background-color: #60513E;
    background-color: rgb(183, 148, 214);
    color: white;
}

.landing-review-container img {
    height: 18rem;
    width: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
}

.landingStar {
    color: goldenrod;
    font-size: 1.5rem;
}

.landing-review-container {
    padding-top: 3rem;
    padding-bottom: 5rem;
    text-align: center !important;

}

.custom-bg-color-1 {
    background: -o-linear-gradient(bottom, rgba(234, 207, 255, 1) 0%, rgba(251, 251, 251, 1) 100%);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(234, 207, 255, 1)), to(rgba(251, 251, 251, 1)));
    background: linear-gradient(0deg, rgba(234, 207, 255, 1) 0%, rgba(251, 251, 251, 1) 100%);
}

.custom-order-us {
    background-color: #e9e6f5;
}

.rounded-custom {
    border-radius: 1.5rem;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    -ms-border-radius: 1.5rem;
    -o-border-radius: 1.5rem;
}

.custom-height100vh {
    height: 100vh;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.orderImg {
    -webkit-box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, .13), 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, .11);
    box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, .13), 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, .11);
}

.landingPageImage img {
    max-height: 300px;
    min-height: 250px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 7px;
}

.btn-buyNow:hover {
    background-color: #f1cd7a98;
    /* color: white; */
    font-weight: bold;
}

.HeroSection::before {
    position: absolute;
    content: "";
    background-color: black;
    width: 100%;
    left: 0;
    height: 100%;
    opacity: .7;
    z-index: -1;
}