.galleryImg img {
    /* width: 255px; */
    /* width: 45%;
    height: 300px;
    border-radius: 10px;
    margin: 10px;
    object-fit: cover; */

    /* instagram view */
    /* width: 32%;
    height: 14rem; */
    /* border-radius: 10px; */
    margin: 2px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 48%;
    height: 18.75rem;
}

img.imgsize {
    /* width: 8.5rem; */
    width: 100%;
    height: 8.2rem;
    -o-object-fit: cover;
    object-fit: cover;
}

img.homeGalleryImgSize {
    /* width: 8.5rem; */
    width: 100%;
    height: 14.2rem;
    -o-object-fit: cover;
    object-fit: cover;
}

.galleryImg {
    margin-top: -0.5rem;
}

.galleryRow .col-4 {
    padding: 1.5px !important;
}

.gallerySlider img {



    height: 300px;
    width: 100%;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover;
}

.seeMoreBtn {
    cursor: pointer;
    color: #3D1F1B;
    text-decoration: none;
}

.galleryDisplayModal .modal-dialog .modal-content {
    background-color: transparent !important;
}

.galleryDisplayModal .modal-content .modal-body img {
    /* border-radius: 21px; */
    max-height: 95vh;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

#galleryModal .modal-header {
    border-bottom: none;
}

#galleryViewModal .modal-content {
    /* border-radius: 21px; */
    background: white;
}

.galleryViewModal {
    padding: 0px !important;
    overflow-y: unset !important;
}

.gallerySwiper {
    height: 100vh;
}

.imageResturant {
    grid-gap: 0.5rem;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
    margin-right: -1rem !important;
    margin-left: -1rem !important;
}

.gallerySwiper .swiper-wrapper .swiper-slide.swiper-slide-active {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.gallaryViewModelHeader {
    display: unset;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 99;
}

.modal-body.gallaryViewModelBody {
    padding: 0;
    background: black;
}


/* .mobileDisplay.swiper-button-next:after,
.swiper-button-prev:after {
  color: rgb(245, 245, 245);
  font-size: 1.5rem !important;
  height: 30px;
  width: 28px;
  font-weight: bold;
} */
.galleryRow .col-6 {
    padding-right: calc(var(--bs-gutter-x) * .1);
    padding-left: calc(var(--bs-gutter-x) * 0);
    padding-bottom: 0.1rem;
    height: 10rem;
}

.galleryRow .col-6 img {
    height: 100%;
}

@media only screen and (max-width:395px) {


    .imageResturant {
        grid-gap: 0.2rem;
        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

}

@media only screen and (min-width:396px) {
    .imageResturant img.imgsize {
        width: 8.8rem;
        height: 7rem;
        -o-object-fit: cover;
        object-fit: cover;
    }

}

@media only screen and (max-width:380px) {
    .imageResturant img.imgsize {
        width: 8rem;
        height: 7rem;
        -o-object-fit: cover;
        object-fit: cover;
    }

}

@media only screen and (max-width:2000px) {


    .galleryImage-test-image {
        width: 19.313rem !important;
        height: 19.313rem !important;
    }
}

@media only screen and (max-width: 1313px) {
    .galleryImage-test-image {
        width: 16.4rem !important;
        height: 16.4rem !important;
    }
}

@media only screen and (max-width:1200px) {


    .galleryImage-test-image {
        width: 16.4rem !important;
        height: 16.4rem !important;
    }
}



@media only screen and (max-width:1146px) {


    .galleryImage-test-image {
        width: 14.4rem !important;
        height: 14.4rem !important;
    }
}

@media only screen and (max-width:999px) {


    .galleryImage-test-image {
        width: 12.4rem !important;
        height: 12.4rem !important;
    }
}

@media only screen and (max-width:871px) {


    .galleryImage-test-image {
        width: 10.4rem !important;
        height: 10.4rem !important;
    }
}

@media only screen and (max-width:770px) {
    .gallery-test {
        display: none !important;
    }

    .seeMoreBtn {
        display: none !important;
    }
}

.black-Remove {
    display: none;
}

.white-Remove {
    display: unset;
}



/* @media only screen and (max-width:805px) {
    .galleryImage img {
        width: 43%;
    } 
} */

@media only screen and (max-width: 992px) {
    .gallaryViewModelBody {
        padding: 0;
    }

    .gallaryViewModelHeader {
        display: none !important;
    }

    /* .swiperGallery .swiper-button-prev {
        display: none;
    }

    .swiperGallery .swiper-button-next {
        display: none;
    } */
}

/* .galleryImg {
    margin-left: 1rem;
} */
/* #galleryModal .modal-dialog {
    
    max-width: 100%;
    width: auto !important;
    display: inline-block;
}

#galleryModal {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.modal {
    z-index: 9999999;
} */




.gallery-test {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 0px;
}

.galleryImage-test {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
}

.galleryImage-test-image {
    -o-object-fit: cover;
    object-fit: cover;
    /* height: 100% !important; */
    /* transform: scale(1) translateX(0%) translateY(-18.2609%); */
    margin-right: 4px;
    margin-bottom: 4px;
    width: 26.5rem;
    height: 26.5rem;
}

@media only screen and (max-width:1500px) {
    img.homeGalleryImgSize {
        /* width: 8.5rem; */
        width: 100%;
        height: 13.2rem !important;
        -o-object-fit: cover;
        object-fit: cover;
    }

}

@media only screen and (max-width:1300px) {
    .black-Remove {
        display: unset;
    }

    .white-Remove {
        display: none;
    }

    img.homeGalleryImgSize {
        /* width: 8.5rem; */
        width: 100%;
        height: 12.2rem !important;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

@media only screen and (min-width:1920px) {
    img.homeGalleryImgSize {
        /* width: 8.5rem; */
        width: 100%;
        height: 17.2rem !important;
        -o-object-fit: cover;
        object-fit: cover;
    }
}