.divPost {
  margin: 10px;
  border: 1px solid;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.post-share {
  flex-direction: column;
}

.post-share h7 {
  font-size: 12px;
}

.post-share h6 {
  margin-bottom: 0px;
}

.shared-PostLike h5 {
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.sharepostImg {
  border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -ms-border-radius: 10px 10px 0px 0px;
  -o-border-radius: 10px 10px 0px 0px;
}

.sharepostImg img {
  border-radius: 10px 10px 0px 0px !important;
  -webkit-border-radius: 10px 10px 0px 0px !important;
  -moz-border-radius: 10px 10px 0px 0px !important;
  -ms-border-radius: 10px 10px 0px 0px !important;
  -o-border-radius: 10px 10px 0px 0px !important;
}

.shared-MainPostLike h5 {
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 20px;
}

.postLikeDislike hr {
  margin: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.postLikeDislike {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 20px;
  padding-right: 20px;
}

.postRectImage {
  width: 2rem;
  height: 2rem;
  /* margin: 0px; */
}

.shared-MainPostLike {
  padding-left: 10px;
}
