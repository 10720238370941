.slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.slider {
    display: flex;
    transition: transform 0.4s ease-in-out;
    width: 100%;
}

.slider-content {
    display: flex;
    width: 100%;
}

.slide {
    min-width: 100%;
    box-sizing: border-box;
}

.slider-button {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}

.slider-button.prev {
    left: 10px;
}

.slider-button.next {
    right: 10px;
}