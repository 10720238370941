.close-btn{
    width: 0.8em;
    height: 1.5em;
    overflow: hidden;
    cursor: pointer;
}

.close-btn > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.unavailable-text{
    font-size: 0.9rem;
}