/* .image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
} */

/* .row {
  display: flex;
  justify-content: center;
} */
.container-black {
  /* width: 80%; */
  margin: 0 auto;
  padding: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* border: solid #bbbb; */
  background-color: black;
}

.commentImageDrawer {
  z-index: 99999;
  border-radius: 20px 20px 0 0;
}

.CommentImageDiv::-webkit-scrollbar {
  /* overflow-x: auto; */
  /* height: 127px;
 width: 127px; */
  display: none;
}

.commentImgDraware {
  width: 25px;
  margin: 1rem;
}

.commentImgDrawarespanbtn {
  font-size: 16px;
}

.imgCommentDiv {
  height: 127px;
  width: 127px;
}

.commentImgRowbtn .btn:hover {
  background-color: #e0e0e0;
  color: #000000;
}

.commentImgRowbtn .btn:active {
  border: none;
}

.imgtxt {
  width: 25px;
  height: 25px;
  font-size: 18px;
  margin: 10px 5.8rem;
}

.CommentAddImg {
  height: 127px;
  width: 127px;
  border-radius: 15px;
  background-color: #e2e2e2;
}

.commentImageDrawerHr {
  width: 5rem;
  background-color: #000000;
  height: 5px;
}

.imageDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 10rem !important;
  width: 14rem;
}

.imageDiv img {
  height: 100% !important;
}

.imageDivForTwo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 7rem !important;
  width: 20rem;
}

.imageDivForOne {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 9rem !important;
  width: 20rem;
}

.imageDivForFour {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.image {
  margin: 0px;
  position: relative;
}

.Commentimage img {
  /* width: 20rem; */
  height: auto;
  position: relative;
  max-height: 14rem !important;
}

/* .commentImgRemainingDiv {
  background: rgba(11, 10, 10, 0.35);
  position: absolute;
  width: 100%;

  bottom: 0;
  border-radius: 0px 0px 10px 10px;
  top: 34%;
  text-align: center;
} */

.commentImgRemainingDiv {
  background: rgba(11, 10, 10, 0.35);
  /*background-image: url("./../../../../Assets//Icons//image-.png");
  */
  position: absolute;
  /*width: 20rem;
  */
  /*width: 100%;
  height: 100%;
  */
  bottom: 0;
  border-radius: 0px 0px 10px 10px;
  color: white;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px;
  right: 0;
  bottom: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}


.remainingNumber {
  color: #ffff;
  font-weight: 500;
  font-size: 1rem !important;
  /* position: absolute; */
  top: -7px;
  left: 10px;
  /* margin: auto; */
}

.image1Size {
  max-width: 66% !important;
}

.imgPadding {
  padding: 2px;
}

.commentImageDrawer {
  display: none;
}

.imageDivForFour img {
  height: 100%;
}

@media only screen and (max-width: 688px) {

  /* .remainingNumber {
    color: #ffff;
    font-weight: 500;
    font-size: 1rem !important;
    position: absolute;
    top: -7px;
    left: 12px;
  } */
  .commentImageDrawer {
    display: unset;
  }

  .imageDivForFour {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-height: 13rem;
  }
}