.pillItems {
  gap: 7px;
  justify-content: center;
  align-items: center;
  padding: 9px;
  margin-right: 36px;
  margin-left: -28px;
}

.pillSticky-filterBar {
  width: 100%;
  display: block;
}

.pillSticky-menu {
  width: 100%;
}

.scrollbtns-filterBar {
  background-color: white;
}

.menuSelectionImage {
  max-width: 20%;
  height: auto;
}

.scrollbtn-menu {
  background-color: white;
  background-color: rgba(255, 255, 255, 0.4);
  display: none;
}

.pillItems p {
  font-size: 11px;
  margin: auto;
}

.menuSelection h4 {
  font-weight: 600;
  margin: auto;
}

.menuSelection-filter::-webkit-scrollbar {
  height: 5px;
}

.menuSelection-filter::-webkit-scrollbar-track {
  background: #d2d2d2;
}

.menuSelection-filter::-webkit-scrollbar-thumb {
  background-color: #f0f0f0;
  border-radius: 20px;
}

/* .row-filterbar{
    display: none;
} */

.searchBar {
  background-color: #fff;
  border: none;
  border-radius: 50px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12);
  font-size: 18px;
  height: 100%;
  /* margin-bottom: 10px; */
  padding: 8px 8px 7px;
  width: 100%;
  position: relative;
}

.search-icon {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 13px; */
  margin-left: 12px;
  font-size: 18px;
  color: rgb(70, 65, 65);
  top: 34.5%;
}

@media only screen and (max-width: 690px) {
  .search-icon {
    margin-left: 5px;
    top: 15px;
  }
}

/* .searchBar {
  width: 100%;
  height: 45px; 
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  font-weight: 400 !important;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  transition: box-shadow 0.3s ease; 
} */

/* .searchBar:focus {

  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); 
}

.searchBar::placeholder {
  color: #aaa; 
}

.searchBar:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

/* .searchBar {
  width: 100%;
  height: 100%;
  border: none;
  padding: 8px;
  font-size: 16px;
  background-color: white;
  border-radius: 50px;
  padding-bottom: 7px;
  margin-bottom: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
} */

.selectionButton .css-13cymwt-control {
  border-radius: 18px;
}

.selectionButton .css-b62m3t-container {
  padding-left: 0px;
  padding-right: 0px;
}

.selectionButton .css-t3ipsp-control {
  border-color: gray !important;
  border-radius: 18px !important;
  box-shadow: none;
}

.searchBottommDiv {
  /* height:'24rem',width:'16%' ,position:'absolute', backgroundColor:'red', zIndex:'999', marginTop:'3rem', borderRadius:'18px' */
  height: 20rem;
  padding: 10px;
  width: 22%;
  position: absolute;
  background-color: white;
  z-index: 1000;
  border-radius: 0px 0px 18px 18px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
  color: #381b17;
  overflow-y: scroll;
  /* Apply a vertical scrollbar */
  scrollbar-width: thin;
  /* "auto" or "thin" or "none" (for Firefox) */
  scrollbar-color: #020202 #f1f1f1;
  /* thumb color track color (for Firefox) */
}

.searchBottommDiv::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

.searchBottommDiv::-webkit-scrollbar-thumb {
  background-color: #020202;
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Radius of the scrollbar thumb corners */
}

.searchBottommDiv::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color of the scrollbar track */
}

@media only screen and (max-width: 688px) {
  .searchBar {
    width: 108%;
    margin-left: -12px;
  }

  .searchBottommDiv {
    width: 94%;
  }
}

@media only screen and (max-width: 768px) {
  .menuSelectionImage {
    max-width: 12%;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .pillSticky-filterBar {
    position: fixed;
    left: 0;
    top: 0px;
    z-index: 999999;
    padding: 0 !important;
  }

  .pillSticky-menu {
    position: fixed;
    top: 5.3rem;
    z-index: 999999;
    padding: 0 !important;
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .scrollbtns-filterBar {
    display: none;
  }

  /* .row-filterbar{
        display: unset;
    } */
}

@media only screen and (min-width: 1373px) and (max-width: 1551px) {
  .menuSelection h4 {
    font-size: 130%;
  }
}

@media only screen and (min-width: 1109px) and (max-width: 1373px) {
  .menuSelection h4 {
    font-size: 100%;
  }
}

@media only screen and (min-width: 887px) and (max-width: 1109px) {
  .menuSelection h4 {
    font-size: 75%;
  }

  /* .searchBox{
    min-width: 300px;
  } */
}

@media only screen and (min-width: 767px) and (max-width: 887px) {
  .menuSelection h4 {
    font-size: 56%;
  }
}

@media only screen and (max-width: 333px) {
  .menuSelection h4 {
    font-size: 96%;
  }
}

.searchOption {
  display: none;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 5px;
  top: 110%;
  right: 0px;
  left: 0px;
  box-shadow: 0px 0px 2px gray;
  border-radius: 10px;
  z-index: 1000;
  width: 100%;
  /* min-width: 300px; */
  max-height: 20rem;
  overflow-x: scroll;
}

.dropdown-item.active,
.dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color: #d0e1fd;
}

.searchOption button {
  background-color: none;
}

.search-term {
  color: rgb(52, 142, 245)
}

.showSearchOption {
  display: block;
}

.divider-suggestions {
  border: 1px solid darkgray;
  margin-top: 10px;
  opacity: 0.6;
}

.section-suggestion {
  /* background-color: #8c5d565b; */
  /* border-radius: 50px 50px; */
  min-width: 50px;
  /* color: rgb(0, 0, 0); */
  padding: 3px 5px;
  font-size: 12px;
}

.section-keyword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
  margin-left: auto;
}

.section-keyword-mobile-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
  margin-left: auto;
}

.section-keyword-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-left: auto;
  display: none;
}

.suggestedCategory{
  margin: 0px;
  padding: 0px;
  color: black;
}

.sectionKeywordMatchLower{
  display: none;
}

@media only screen and (max-width: 1650px){
  .section-keyword{
    display: none;
  }

  .sectionKeywordMatchLower{
    display: block;
  }
}


@media only screen and (max-width: 780px){
  .section-keyword{
    display: block;
  }

  .sectionKeywordMatchLower{
    display: none;
  }
}

@media only screen and (max-width: 500px){
  .section-keyword-mobile-default {
    display: none;
  }

  .section-keyword-mobile{
    display: block;
  }
}