 .login-Registration {
     z-index: 9999;
     right: 1rem;
     top: 1rem;
 }

 .login-Registration-white {
     color: white !important;
 }


 .login-Registration-coffee {
     color: #371B17 !important;
 }

 .cart-design-with-login {
     gap: 10px;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
 }

 .searchInput {
     z-index: 9999;
     text-align: center;
     top: 2rem;
     left: 50%;
     border-radius: 50%;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
     width: 24rem;
     margin: auto;
 }

 .search-field {
     border-radius: 40px;
     -webkit-border-radius: 40px;
     -moz-border-radius: 40px;
     -ms-border-radius: 40px;
     -o-border-radius: 40px;
     background-color: white;
     padding: 5px;
 }

 .search-field-color-white {
     background-color: white;
     width: 24rem;
     margin: auto;
 }

 .search-field-color-coffee {
     -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08),
         0 4px 12px rgba(0, 0, 0, 0.05);
     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08),
         0 4px 12px rgba(0, 0, 0, 0.05);
     border: 1px solid #DDDDDD;
     background-color: white;
 }

 .vl {
     border-left: 20px solid #371B17;
     height: 1px;
     width: 10px;
     transform: rotate(90deg);
     -webkit-transform: rotate(90deg);
     -moz-transform: rotate(90deg);
     -ms-transform: rotate(90deg);
     -o-transform: rotate(90deg);
 }

 .searchIcon {
     width: 1rem;
 }

 .searchIconcoffee {
     width: 2rem;
     padding: 4px;
     margin-left: 5px;
     /* margin-right: 10px; */
 }

 .btn-search {
     background-color: #371B17;
     border-radius: 50%;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
 }

 .btn-search:hover {
     background-color: #371B17;
     opacity: .9;
 }

 .card-Search {
     position: fixed;
     background-color: rgb(110, 3, 3);
     top: 50%;
     left: 50%;
     z-index: 99999;
     width: 1rem;
 }

 .brand {

     z-index: 9999;
     top: .5rem;
 }

 .brandname {

     text-decoration: none;
     font-size: 1.2rem;
 }

 .brandname-color-white {
     color: white;
 }

 .brandname-color-coffee {
     color: #371B17;
 }


 .navbarGoTaste {
     position: fixed;
     top: 0;
     z-index: 999;
     width: 100vw;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-pack: justify;
     -ms-flex-pack: justify;
     justify-content: space-between;
     padding: 10px;
     /* transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out; */
     /* padding-left: 28px;
    padding-right: 28px; */
 }

 .backgroundTranparent {
     background-color: rgba(0, 0, 0, .1) !important;
 }

 .backgroundWhite {
     background-color: white !important;
 }

 .slideUp {
     top: 0px !important;
 }

 .slideDown {
     top: -100px !important;
 }

 .slideUpBottom {
     bottom: 0px !important;
 }

 .slideDownBottom {
     bottom: -100px !important;
 }


 .bottomBar {
     position: fixed;
     bottom: 0px;
     width: 100vw;
     background-color: white;
     /*height: 10px;
    */
     z-index: 999;
     padding: 10px;
     transition: all .3s ease-in-out;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
 }

 .Navbar-custom {
     height: 100%;
 }

 .explore {
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
 }

 .explore span {
     font-size: 10px;
 }

 .bottomBar-Item {
     gap: 53px;
 }

 .searchForFood {
     font-size: 14px;
 }

 .searchBtnText {
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     -webkit-box-pack: start;
     -ms-flex-pack: start;
     justify-content: start;
     -webkit-box-align: start;
     -ms-flex-align: start;
     align-items: start;
     margin-left: 10px;
 }

 .searchBtnText span {
     font-size: 13px;
     color: #371B17;
     opacity: .5;
 }

 .profileImage {
     width: 2rem;
     height: 2rem;
     border-radius: 50%;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     -ms-border-radius: 50%;
     -o-border-radius: 50%;
 }

 .btn-search-Mobile {
     width: 100%;
 }

 .btn-search-Mobile:active {
     border-color: transparent !important;
 }

 .dropdown-Icons {
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     gap: 10px;
 }

 .dropdownBtn {
     border: 1px solid rgba(128, 128, 128, 0.414);
     border-radius: 40px;
     -webkit-border-radius: 40px;
     -moz-border-radius: 40px;
     -ms-border-radius: 40px;
     -o-border-radius: 40px;
 }

 .dropdownBtn:hover {
     -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05);
     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.05);

 }

 .dropdown-color-white {
     background-color: white;
 }

 .btnLogin.show {
     border-color: transparent !important;
 }

 .btnLogin:first-child:active {
     border-color: transparent !important;
 }

 .dropdownItem:active {
     background-color: #371B17 !important;
 }

 .dropdownItem {
     cursor: pointer;
 }

 .btn-Search-Prop {
     font-size: 14px;
     font-weight: 500;
     color: #371b17e6;
 }

 .btn-Search-Prop:active {
     border-color: transparent !important;
 }

 .close-btn {
     background-color: transparent;
     border: none;
     color: black;
 }

 .searchDialog {
     /* margin-left: -9rem; */
     margin-top: 1rem !important;
     overflow-y: auto;
     position: absolute;
     height: 30rem;
     width: 100%;
     background-color: white;
     margin-top: 1rem;
     border-radius: 15px;
     padding: 10px 30px;

 }

 .searchDialog::-webkit-scrollbar {
    width: 0.7em;
    background-color: white;
    margin: 1em 0;
  }

  .searchDialog::-webkit-scrollbar-thumb{
    background: #888;
    border-radius: 0.4em;
  }

  .scroll-bar{
    overflow-y: auto;
  }

 .searchInputField {
     width: 21rem;
     border: transparent;
     outline: none;
     background-color: #eeeeee;
 }

 .searchInputFieldParent {
     background-color: #eeeeee;
 }

 .searchDialog .nav-link {
     color: #3D1E19 !important;
 }

 .nav-tabs{
    border-bottom: 1px #bdbdbd solid;
 }

 .nav-link.active{
    border-color: #bdbdbd #bdbdbd #ffffff !important;
 }

 .mobileSearchBar {
     border: #6e6d6d;
     border-radius: 10px;
     background-color: rgb(237 237 237);
     height: 3rem;
 }

 .mobileSearchInput {
     border: transparent;
     outline: none;
     background-color: rgb(237 237 237);
 }

 .searchDialogImg {
     width: 2.4rem;
     height: 2.4rem;
     border-radius: 50%;
     -o-object-fit: cover;
     object-fit: cover;
 }

 .searchLabel {
     height: 1.2rem;
     width: 4rem;
     border-radius: 50px;
     background-color: #7390e5;
 }

 .searchLabel p {
     color: white;
     font-size: 0.8rem;
     margin-left: 1rem;
 }

 .matched-text {
    /* width: max-content;
    margin-left: 1em;
    background-color: #8c5d56; */
    font-size: 0.8rem;
    text-transform: capitalize;
    font-weight: 500;
    color: #6d6d6d;
 }

 .matched-description{
    margin: 0.5em 0;
 }

 /* .matched-text p{
    margin: 0 1rem;
 } */

 .searchText {
     font-weight: 600;
 }

 .searchContentMargin {
     margin-right: 1rem;
 }

 .searchTitile {
     color: #3D1E19;
     margin-bottom: 0px;
     font-size: 14px;
     text-decoration: none;
 }

 .searchStar {

     width: 1rem;
     height: 1rem;
     margin-right: 5px;
     margin-top: 1px;
 }

 .searchratingText {
     font-size: 13px;

 }

 .searchLabelPost {
     height: 1.2rem;
     width: 4rem;
     border-radius: 50px;
     background-color: #fea49d;
 }

 .searchLabelPost p {
     color: white;
     font-size: 0.8rem;
     margin-left: 1rem;
 }

 .searchLabelRestaurant {
     height: 1.2rem;
     width: 6.5rem;
     border-radius: 50px;
     background-color: #c8a995;
 }

 .searchLabelRestaurant p {
     color: white;
     font-size: 0.8rem;
     margin-left: 1rem;
 }

 #myTab button {
     font-weight: 600;
     color: #3D1E19
 }

 .modalParentDiv .modal-header .btn-close {
     margin: 0px;
 }

 .modalParentDiv .modal-header {
     border-bottom: none;
 }

 /* notifaction */
.notification_details{
    position: absolute;
    top: 4rem;
    right: 2rem;
    
}
.notification_modal{
    max-width: none!important;
    width: 450px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 6px;
    background: white;
    padding: 10px;
    /* overflow-y: scroll; */
    /* max-height: calc(100vh - 100px); */
    /* max-height: 400px; */
}
 .notification_container .bellIcon{
    width: 40px;
    height: 40px;
    fill: white;
    cursor: pointer;
    margin-top: 5px;
    background: #371b17;
     padding: 10px;
     border-radius: 50%;
}
.notification_container h5{
    font-size: 18px;
    
}

.notification_modal h5{
    font-size: 18px;
    font-weight: 600;
}
.notfication_cat{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;margin: 0;
    margin: 15px 0;
    border-bottom: 1px solid #7B7B7B;
    padding-left: 0;
}
.notfication_cat li{
    flex: 1;
    text-align: center;
    padding-bottom: 15px;
    font-size: 18px;
    cursor: pointer;
    color: #7B7B7B;
}
.notfication_cat li:nth-child(2) {
    border-left: 1px solid #7B7B7B;
    border-right: 1px solid #7B7B7B;
}
.notfication_cat .active{
    font-weight: 600;
    color: black;
}
.liked_reply{
    display: flex;
    gap: 20px;
    padding: 0 10px;
    margin-bottom: 15px;
}
.liked_reply .desc{
    color: #7B7B7B;
    font-size: 15px;
}
.not_img svg, .not_img img{
    fill: #fea49d;
    width: 28px;
    height: 28px;
}
.name_img{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #7B7B7B;

}
.name_img .userImg {
    width: 28px;
    height: 28px;

}
.name_img .name{
    font-weight: 800;
    margin: 0 5px 0 5px;
    font-size: 16px;
    color: #000; 
}
span.count {
    background: red;
    position: absolute;
    top: 0;
    min-width: 22px;
    padding: 2px 6px;
    border-radius: 50%;
    text-align: center;
    color: white;
    min-height: 16px;
    font-size: 12px;
}
span.unSeen_not {
    width: 10px;
    background: rgb(114, 105, 245);
    height: 10px;
    border-radius: 50%;
    margin-left: 8px;
    margin-top: -5px;
}

.Search-info-container{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    cursor: pointer;
}

.search-result-icon{
    width: 1em;
    height: 2em;
    overflow: hidden;
}

.search-result-icon > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.loading-text{
    text-align: center;
    margin-top: 1em;
}

@media only screen and (max-width: 991px) {
    .notification_modal{
        max-width: none!important;
        width: 90vw;
    }
    .liked_reply{
        padding: 0;
    }
    .notfication_cat li{
        padding: unset;
        
        padding-bottom: 10px;
    }
 
    
  }

