/* .restaurantCards {
  margin-top: 2rem;
} */


.swiper-container{
  position: relative;
  height: auto;
}

.custom-prev-button, .custom-next-button{
  position: absolute;
  top: 50%;
  z-index: 4;
  border-radius: 50%;
  width: 1.7em;
  height: 1.7em;
  overflow: hidden;
  border: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  transform: translateY(-50%);
}

.custom-prev-button{
  left: 0%;
}

.custom-prev-button > img, .custom-next-button > img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.custom-next-button{
    right: 0%;
}

.default-text{
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5)
}