.modal-container {
    width: min(20em, 100%);
    background-color: rgba(0, 0, 0, 0.7);
    height: 7em;
    border-radius: 0.5em;
    margin-left: auto;
    display: flex;
    padding: 1em;
    margin-bottom: 0.5em;
}

.modal-container-small {
    width: min(12em, 100%);
}

.hide {
    display: none;
}

.image-container {
    position: relative;
}

.image-container-width {
    width: min(7em, 100%) !important;
}

.dnd-item {
    width: 100%;
    height: 7em;
    overflow: hidden;
}

.dnd-item>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.file-container {
    width: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1em;
    cursor: pointer;
}

.file-label {
    width: 2em;
    height: 2em;
    overflow: hidden;
    cursor: pointer;
}

.file-label img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.close-button {
    width: 1.2em;
    height: 1.2em;
    overflow: hidden;
    position: absolute;
    top: 4%;
    right: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.close-button>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.grab-cursor {
    cursor: grab;
}

.grabbing-cursor {
    cursor: grabbing;
}

.layer-icon {
    width: 2.5em;
    height: 2.5em;
    overflow: hidden;
    text-align: right;
    background-color: rgba(26, 26, 26, 0.8);
    padding: 0.5em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-bottom: 0.5em;
    cursor: pointer;
    transition: opacity 0.2s;
}

.layer-icon:hover{
    opacity: 0.5;
}

/* .layer-icon>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */