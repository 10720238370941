/* height:'5rem', width:'25%', backgroundColor:'black', color:'white', fontSize:'1.5rem', borderRadius:'10px' */
.allcard{
    height: 5rem;
    width: 25%;
    background-color: #ffff;
    color: black;
    font-size: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
}

/* height:'5rem', width:'25%', backgroundColor:'black',objectFit:'cover' */
.searchOfferDiv{
    height: 5rem;
    /* width: 25%; */
    width: 100%;
    background-color: #ffff;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.12);
    background: linear-gradient(to bottom, transparent 30%, black 96%);
}
.searchOfferDiv img{
    
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.searchOfferDiv-details{
    
    position: absolute;
    top: 10%;
    color: white;
    margin-left: 5px;
}
.searchOfferDiv-details p{
    font-size: 12px;
}
.selectedItem{
    /* height:'2rem', backgroundColor:'#d9d9d9', borderRadius:'20px', */
    height: 2rem;
    background-color: #d9d9d9;
    border-radius: 20px;
}
    
