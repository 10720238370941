/* gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}
mapouter{position:relative;text-align:right;height:100%;width:100%;} */
mapouter {
  position: relative;
  text-align: right;
  height: 100vh;
  width: 20rem;
}

gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100vh;
  width: 20rem;
}

/* 
.backgroundBlur {
  -webkit-filter: blur(3px);
  filter: blur(3px);
} */

.keyword-image {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.keyword-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 10px;
  width: 100%;
}

.keyword-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.item-container {
  display: flex;
  flex-direction: column;
}

.food-details-cart {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.details {
  max-width: 300px;
  padding: 10px;
  border-radius: 5px;
  /* background-color: rgba(121, 121, 129, 0.041); */
}

.commentLove {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: center;
}

.search-result-container {
  margin: 0 1em;
}

.keyword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
}

.remove-image{
  width: 1em;
  height: 1.5em;
  overflow: hidden;
  position: absolute;
  top: -4%;
  right: 0%;
}

.remove-image > img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.filter-img {
  width: 2em;
  height: 2em;
  overflow: hidden;
}

.filter-img>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.filter-name {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0;
}

.slider-btn-wrapper {
  position: relative;
  height: auto;
}

.prev-button, .next-button {
  top: 50% !important;
  z-index: 100 !important;
  transform: translateY(-50%);
  cursor: pointer;
}

.slider-btn-wrapper img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slider-content{
  cursor: pointer;
  min-height: 0;
  position: relative;
}

.slider-content::before{
  content: "";
  position: absolute;
  top: 24%;
  left: 0%;
  width: 1px;
  height: 2em;
  background-color: rgba(0, 0, 0, 0.3);
}

.active-filter{
  border-bottom: 3px solid #0060ff;
  background-color: #72a4f754;
  border-radius: 4px;
}