.cart {
  z-index: 999999;
  bottom: 20px;
  right: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 10px;
  /* justify-content: space-around; */
}

.cart .popup {
  background-color: #371b17;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.cart .popup-bottom {
  /* background-color: #371b17; */
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 6px;
  cursor: pointer;
}

.cart-details {
  background-color: #f9f9f9;
  width: 35rem;
  height: fit-content;
  /* overflow-y: auto; */
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 10px;
  position: absolute;
  /* top: 5rem;
    right: 5rem; */
  top: 4rem;
  right: 2rem;
}

.tab-content-container {
  position: relative;
  height: 100%;
}
.staticcheckout-btn {
  position: sticky; 
  bottom: 2%; 
  left: 0;
  width: 100%; 

  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); 
  z-index: 100; 
}



@media only screen and (max-height: 720px) {
  .cart-details {
    height: 36em;
    overflow-y: auto;
  }
}

@media only screen and (max-height: 634px) {
  .cart-details {
    height: 30em;
    overflow-y: auto;
  }
}

.cart-details::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.cart-details::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cart-details::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.4em;
}

/* Handle on hover */
.cart-details::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bin-img {
  width: 1.3rem !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.popup img {
  width: 1.5rem;
}

.cart-details img {
  /* width: 7rem; */
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  /* height: 100%; */
  -o-object-fit: cover;
  object-fit: cover;
}

/* .productDetails {
  width: min(17em, 100%);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
} */


.large-tooltipsInfo {
  position: relative;
}

.large-tooltipsInfo::after {
  z-index: 58646200 !important;
  content: attr(data-title);
  position: absolute;
  bottom: 100%; /* Position above the element */
  left: 170%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 1em; /* Larger font size */
  line-height: 1.5em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s 0.3s;
  width: max-content;
}

.large-tooltipsInfo:hover::after {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.productDetails h5 {
  color: #3d1e19;
  font-weight: 700;
}

.price {
  color: #3d1e19;
  font-weight: 700;
  margin-bottom: 0px;
}

.price-Div {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.itemDetailsAndImage {
  width: min(48em, 100%);
  gap: 10px;
}

.quantity {
  gap: 10px;
}

.quantity {
  padding: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.plus-icon {
  cursor: pointer;
}

.btn-checkout {
  background-color: #371b17;
  color: white;
  width: 100%;
}

.btn-checkout:hover {
  background-color: #371b17de;
  color: white;
  width: 100%;
}