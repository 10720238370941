/* .swiper-button-prev::after, */
/* .menuslider .swiper-button-next::after {
  color: gray;
  padding: 0;
} */
.menuslider .swiper-button-prev {
  background: none;
  /* background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"><rect x="0" y="0" width="24" height="24" fill="gray" rx="12" ry="12"/><path d="M10.59 16.41L12 15l6-6-6-6-1.41 1.41L14.17 9z" fill="none"/></svg>'); */
  background-repeat: no-repeat;
  background-size: 55% auto;
  background-position: center;
  overflow: hidden;
}

.menuslider .swiper-button-next {
  background: none;
  /* background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"><rect x="0" y="0" width="24" height="24" fill="gray" rx="12" ry="12"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill="none"/></svg>'); */
  background-repeat: no-repeat;
  background-size: 55% auto;
  background-position: center;
  overflow: hidden;
}

.menuslider .swiper-button-next:hover {
  color: lightgray;
  background-color: #ffffff;
}

.menuslider .swiper-button-prev:hover {
  color: lightgray;
  background-color: #ffffff;
}

.menusliderDiv {
  padding: 1rem 4rem 1rem 0rem;
}

.menuslider img {
  width: 4rem;
  height: 4rem;
  border-radius: 3px;
}

.cardMargin {
  /* margin: 0.5rem; */
  padding: 1rem;
  height: auto;
}

.cardMargin img {
  /* width: 2rem !important;
    height: 2rem !important; */
  -o-object-fit: cover;
  object-fit: cover;
}



.menuSliderMobile {
  display: none;
}

.menuSliderDesktop {
  display: block;

}

.onSelected {
  background-color: rgb(208, 212, 216);
}


@media only screen and (max-width: 688px) {
  .menuSliderDesktop {
    display: none !important;

  }

  .menuSliderMobile {
    display: block !important;
  }


  .cardMargin {
    margin: 0.1rem;
    padding: 0.3rem;
    height: auto;
  }

  .cardMargin h4 {
    /* font-size: 13px;
    
    padding-left: 5px; */
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 11px;
    color: #3d1e19;
    margin-top: 9px;
  }


  .bike-img {
    margin-top: 6%;
  }

  .cardMargin img {
    min-height: .5rem;
    max-height: 2.5rem;
    object-fit: unset;
  }

  .menuslider img {
    width: 2rem;
    height: 2rem;
    border-radius: 3px;
  }
}

.menuslider .swiper-slide {
  /* width: 400px !important; */
}

.slide-animation {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), 0px 6px 6px rgba(0, 0, 0, 0.1);
  margin-right: 20px !important;
}

.slide-animation:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2), 0px 6px 6px rgba(0, 0, 0, 0.1);
  margin-right: 20px !important;
}

.menu-type-icon {
  width: 2em;
  height: 2em;
  overflow: hidden;
}

.menu-type-icon>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}