.chefCooking {
  width: 200px;
  height: 200px;
  border-radius: 8px;
}

.chefCookingIcon {
  width: 38px;
  height: 35px;
}

.deliveryMan {
  width: 100px;
  height: 90px;
}

.chatIcon {
  float: right;
  justify-content: flex-end;
  align-items: end;
  justify-items: end;
  right: 0;
}

.OrderDetails {
  padding-left: 10px;
}

.status {
  display: flex;
  justify-content: space-evenly;
}

.status-tab {
  width: 80px;
  height: 10px;
  border-radius: 8px;
  background-color: #ece6e6fe;
  cursor: pointer;
}

.status-tab.active {
  background-color: black;
}

.tab-2 {
  width: 120px;
}
