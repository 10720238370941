/* Shimmer.css */

.shimmer-wrapper {
  width: 100%;
  height: 600px; /* Full screen height */
  background: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-size: 800px 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(189, 181, 181, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
  position: absolute;
  top: 0;
  left: -150%;
  animation: shimmer 3s ease-in-out infinite; /* Smoother animation */
  transform: skewX(-10deg); /* Slight skew for a more dynamic effect */
}

@keyframes shimmer {
  100% {
    transform: translateX(150%) skewX(-10deg); /* Maintain skew throughout */
  }
}
