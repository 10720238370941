/* Receipt Button */
.receipt-button {
  background-color: #d9d9d9; /* Light gray background */
  color: #4a6bab; /* Subtle blue text */
  border: none; /* Remove border */
  border-radius: 20px; /* Pill shape */
  padding: 8px 20px; /* Padding for a balanced pill button */
  font-weight: 600; /* Bold text */
  cursor: pointer; /* Pointer cursor for clickability */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth hover effect */
  margin-right: 12px;
}

.receipt-button:hover {
  background-color: #c0c0c0; /* Slightly darker gray on hover */
  color: #3a5a8b; /* Slightly darker blue text on hover */
}

/* Ready Button */
.ready-button {
  background-color: #e3e9ff;
  color: #5a7dff;
  border: none; /* Remove border */
  border-radius: 20px;
  padding: 8px 20px; /* Padding for a balanced pill button */
  font-weight: 600; /* Bold text */
  cursor: pointer; /* Pointer cursor for clickability */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth hover effect */
   margin-right: 18px;
}

.text-color {
  color: #3d1e19; /* Darker gray for good contrast */
}

.see-more-btn {
  background: none;
  border: none;
  color: #3577f1; /* Blue color */
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.see-more-btn:hover {
  text-decoration: underline;
}



.price-tag {
  font-size: 16px;
  font-weight: 600;
}

.payment-breakdown {
  background-color: #F9F4F4;
}

.pending-container {
  width: 800px;
  margin: 0 auto;
}

.order-item {
  font-family: "Arial", sans-serif;
  font-size: 0.9rem;
  line-height: 1.4;
  color: #000;
}

.order-item h6 {
  font-size: 1rem;
  margin: 0;
  color: #000;
}

.order-item .status-badge {
  background-color: #e3e9ff;
  color: #5a7dff;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 20px;
}

.order-item ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.order-item .extras-list li {
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.order-item .extras-list li span:first-child {
  white-space: nowrap;
}

.order-item .extras-list li span:last-child {
  margin-left: auto;
  text-align: right;
}

.order-item strong {
  font-size: 0.95rem;
}

.order-item .fw-bold {
  font-weight: 700;
}

.order-item .fw-normal {
  font-weight: 400;
}
