.mobileChat {
    display: none;
}

.cahtSidebar {
    background: #F9F9F9;
    -webkit-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    height: 100vh;
    overflow-y: scroll;
}

.cahtSidebar h4 {
    font-size: 1rem;
}



.reciveMessage {
    font-size: 1rem;
    border-radius: 15px 15px 15px 0px;
    background: #f9f9f9;
    overflow-wrap: anywhere;
    -webkit-box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
    font-weight: 400;
}

.dextopChat .ChatName img {
    width: 3.5rem;
    height: 3.5rem;
}

.sendMessage {
    font-size: 1rem;
    border-radius: 15px 15px 0px 15px;
    background: #0084FF;
    color: white;
    -webkit-box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px;
    overflow-wrap: anywhere;
    font-weight: 400;
}

.chat {
    height: 100vh;
    overflow-y: scroll;
}

.messageSend {
    position: sticky;
    /* position: fixed; */
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 1rem;
    background-color: white;

}

.sendbtn img {
    width: 50px;
}

.sendbtn {

    padding: 0.375rem 0.75rem;

    background-color: var(--bs-form-control-bg);

    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;


}

.ChatName {
    background: #F9F9F9;
    -webkit-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    position: sticky;
    
    width: 100%;
    left: 0;
    top: 0;


}

.ChatName h3 {
    font-weight: 600;
    color: #371B17;


}

.selecedCht {
    border-radius: 1rem;
    background-color: #ECF3FF;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    color: black;
}

.chat p {
    font-size: 12px;

}


.ChatHeader {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bolder;
    font-size: 2.5rem;
    color: #371B17;
}

.backButton {
    width: 2rem;
    margin-right: 1rem;
}

/* mobile devices */
@media only screen and (max-width: 688px) {
    .dextopChat {
        display: none;
    }

    .mobileChat {
        display: block;
    }

    .mobileChat a {
        color: black;
    }
    .chat{
        padding-top: 70px;
        margin-bottom: 100px;
        /* position: fixed; */
    }
    .messageSend{

        position: fixed;
    }
    .ChatName{
        position: fixed;
    }
    .sendbtn img {
        width: 28px;
    }
}

/* tablate devices */

@media only screen and (min-width: 689px) and (max-width: 992px) {

    .dextopChat {
        display: none;
    }

    .mobileChat {
        display: block;
    }

    .mobileChat a {
        color: black;
    }

    .chat{
        padding-top: 70px;
        margin-bottom: 100px;
        /* position: fixed; */
    }
    .messageSend{

        position: fixed;
    }
    .ChatName{
        position: fixed;
    }
    .sendbtn img {
        width: 28px;
    }

}




/* width */
.cahtSidebar::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.cahtSidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px grey;
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.cahtSidebar::-webkit-scrollbar-thumb {
    background: rgb(181, 181, 181);
    border-radius: 10px;
}

/* Handle on hover */
.cahtSidebar::-webkit-scrollbar-thumb:hover {
    background: #6a6a6a;
}

.fixedSearch {
    position: sticky;
    left: 0;
    top: 0;
    background-color: #F9F9F9;
    z-index: 1;
}

.lastMessage {
    font-size: .8rem;
    margin: 0;
    padding: 0;
}


/* no chat */
.no-chat {
    background-color: white;
    color: black;
    font-weight: 600;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;

}

.unread-count {
    background-color: #4395ff;
    color: white;
    padding: 5px 7px;
    border-radius: 50%;
    font-size: 10px;
    min-width: 20px;
    min-height: 20px;
    text-align: center;
    font-weight: 600;
}

.search-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
    padding: 5px 10px;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-icon {
    margin-right: 5px;
}

.search-input {
    border: none;
    outline: none;
    font-size: 16px;
}
button.load_btn {
    background: none;
    margin-left: auto;
    margin-right: auto;
    display: inherit;
    background: blue;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 2px 10px;

}
button.load_btn svg {
   fill: white;
   height: 20px;
   margin-left: 5px;
   
}