.productPageBanner img {
    /* height: 30rem; */
    height: 100vh;
    object-fit: cover;

}

.productPageBannerBottom img {
    height: 30rem;
    object-fit: cover;
}

.SecondaryHeaderHr {
    border: none;
    height: 2px;
    background-color: #371B17;
    margin: 0 auto;
    width: 127px;
    margin-bottom: 20px;
    opacity: 100;
}

.review-container {
    padding-top: 3rem;
    padding-bottom: 5rem;
    text-align: center !important;

}

p.p-4.ImageTextInside {
    font-size: 1.4rem;
    text-align: justify;
}

/* .swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.5) !important;
   height: 1.5rem;
    width: 1.5rem;
    border: 2px solid;
    margin-top: 1rem;
} */

.swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.productPageContainer {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    transform: scale(1.2);
}

.review-container img {
    height: 25rem;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.productPageBody img {
    height: 20rem;
}

.crop-image {
    object-fit: cover;
    width: 240px;
    height: 320px;
    border-radius: 7%;
}

.productPhotoTextCardDiv {
    /* top: 35%; */
    top: 19%;
    max-height: fit-content;
    left: 55%;
    font-size: 35px;
}

.productPageText {
    font-size: 20px !important;
    text-align: justify;
}

.sliderBox {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    place-content: center;
    /* padding: 20px; */
    border-radius: 20px;
    box-shadow: 0px 17px 15px 5px #dad6d6;
}

.productPhotoTextCard {
    color: #f1cd7a;
    background-color: #371b17;
    border-radius: 1.5rem 0 0 1.5rem;

}

.order-md-last-last {
    order: 1;
}

@media only screen and (max-width:768px) {
    .productPageBody {
        justify-content: center;
        text-align: center;
        padding: 0 !important;
        margin: 0 !important;
    }

    .crop-image {
        margin-bottom: 3rem;
        margin-top: 3rem;
    }

    .order-md-last-last {
        order: -1;
    }
}

@media only screen and (min-width:1400px) and (max-width:1800px) {
    .productPhotoTextCardDiv {
        top: 30%;
        left: 40%;
        font-size: 30px;
    }
}

@media only screen and (min-width:1200px) and (max-width:1400px) {
    .productPhotoTextCardDiv {
        top: 20%;
        left: 35%;
    }
}

@media only screen and (min-width:1000px) and (max-width:1200px) {
    .productPhotoTextCardDiv {
        top: 22%;
        font-size: 25px;
        left: 35%;
    }
}

@media only screen and (min-width:850px) and (max-width:1000px) {
    .productPhotoTextCardDiv {
        top: 22%;
        font-size: 23px;
        left: 23%;
    }
}

@media only screen and (min-width:650px) and (max-width:850px) {
    .productPhotoTextCardDiv {
        top: 18%;
        left: 21%;
        font-size: 18px;
    }
}

@media only screen and (min-width:500px) and (max-width:650px) {
    .productPhotoTextCardDiv {
        top: 22%;
        left: 7%;
        font-size: 14px;
        right: 7%;
    }

    .productPhotoTextCard {
        border-radius: 0;
    }
}

@media only screen and (min-width:300px) and (max-width:500px) {
    .productPhotoTextCardDiv {
        top: 26%;
        left: 7%;
        font-size: 10px;
        right: 7%;
    }

    .productPhotoTextCard {
        border-radius: 0;
    }

    .ImageTextInside {
        padding: 0 !important;
    }
}