.banner {
  position: relative;
  /* background-image: url("./../../../Assets/Image/banner.jpg"); */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.bannerHome {
  position: relative;
  background-image: url('./../../../Assets/Image/banner.jpg');
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.navbarColor {
  background-color: rgba(0, 0, 0, 0.1) !important;
  /* background-color: red !important; */
  /* opacity: 0.5; */
  color: white;
  position: absolute;
}

.nav-bg-white {
  color: white;
}

.loginModalCloseBtn h5 {
  font-size: 1.2rem;
}

.loginModalCloseBtn .close {
  background: transparent !important;
  border: none !important;
}

.loginModalCloseBtn .close span {
  font-size: 1rem;
}

.changeBtnDisplay {
  display: none;
}

.helloUserName {
  right: 7rem !important;
  color: white !important;
  top: 1.4rem !important;
}

/* .typewriterDiv {
  font-size: 3rem;
  color: white;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  margin-left: 8rem;
  font-weight: bolder;
} */

/* .Typewriter {
  font-size: 4rem;
} */

.banner-signUp {
  /* position: absolute; */
  z-index: 9;
  right: 1rem;
  top: 1rem;
  background: transparent;
  border: none;
}

button.btn.btn-primary.banner-signUp:hover {
  background: transparent !important;
  color: gray;
}
