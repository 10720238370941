/* App.css or index.css */
.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
    animation: App-logo-spin infinite 20s linear;
  }
}

.test2 .mySwiperTest {
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff;
}

.test2 swiper-container {
  width: 100%;
  height: 100%;
}

.test2 swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.test2 swiper-slide img {
  border-radius: 10px;
}

.test2 swiper-slide img {
  display: sw;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

body {
  background: white;
  color: #000;
}

.test2 swiper-container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.test2 swiper-slide {
  background-size: cover;
  background-position: center;
}

.test2 .mySwiperTest {
  height: 31rem;
  width: 50%;
}

.test2 .mySwiperTest2 {
  height: 15rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 0;
  width: 30%;
  margin: 0px !important;
}

.plusButton {
  width: 100px;
  height: 7rem;
  margin-top: 9px;
  border-radius: 10px;
  background: transparent;
}

.swiper-with-btn {
  gap: 10px;
}

.test2 .mySwiperTest2 swiper-slide {
  width: 25%;
  height: 50%;
  opacity: 0.4;
}

.test2 swiper-slide.swiper-slide-visible img {
  border-radius: 10px;
}

.test2 .mySwiperTest2 .swiper-slide-thumb-active {
  opacity: 1;
}

.test2 swiper-slide img {
  display: sw;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 688px) {
  .test2 .mySwiperTest {
    width: 99% !important;
  }

  .test2 .mySwiperTest2 {
    width: 95% !important;
    height: 10rem;
  }

  .plusButton {
    height: 4.5rem;
  }
}

@media screen and (min-width: 688px) and (max-width: 992px) {
  .test2 .mySwiperTest {
    width: 99% !important;
  }

  .test2 .mySwiperTest2 {
    width: 95% !important;
    height: 10rem;
  }

  .plusButton {
    height: 4.5rem;
  }
}

.btn-modal {
  position: absolute;
  background-color: white;
  z-index: 99;
  right: 10px;
  top: 10px;
  color: white;
}
