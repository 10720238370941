body {
  padding-right: 0 !important;
  overflow-y: auto !important;
}
.signUp {
  background-image: url('./../../Assets//Image/restaurant-interior.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
  height: 100%;
  display: flex;
  align-items: center;
}

.newCOlor {
  background-color: white;
  color: #6d7d86;
  border-color: #dee2e6;
  text-align: start;
}

.filePlaceholder {
  color: #595c5f;
}

.signUpForm {
  margin: 2rem;
  padding: 2rem;
}

/* mobile devices */
@media only screen and (max-width: 688px) {
  .signUpForm {
    margin: 0;
    padding: 2rem;
  }
}

.phoneNumberDiv input::-webkit-outer-spin-button,
.phoneNumberDiv input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.phoneNumberDiv input[type='number'] {
  -moz-appearance: textfield;
}

.custom-ui {
  text-align: center;
  width: 35rem;
  padding: 40px;
  background: white;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: black;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.btn-common {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-weight: 500;
  font-size: 1.2rem;
}

.btn-yes {
  width: 160px;
  padding: 10px;
  border: 1px solid #2ecc71;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #2ecc71;
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}

.btn-yes:hover {
  border: 1px solid #fff;
  background: #2ecc71;
  color: white;
}

.btn-no {
  width: 160px;
  padding: 10px;
  border: 1px solid #e74c3c;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #e74c3c;
  transition: 0.3s all ease-in-out;
  -webkit-transition: 0.3s all ease-in-out;
  -moz-transition: 0.3s all ease-in-out;
  -ms-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
}

.btn-no:hover {
  border: 1px solid #fff;
  background: #e74c3c;
  color: white;
}

.modalLoader .modal-dialog .modal-content {
  background: transparent;
  border: none;
}

.modalLoader .modal-dialog .modal-content .modal-body {
  justify-content: center;
  display: flex;
}
